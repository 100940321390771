import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import * as Const from '../Const';

import ReactJson from 'react-json-view';

class JsonDocs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}
	async componentDidMount() {
		this.setState({ isLoading: true });

		const { merchantBasePath, jsonDocId } = this.props;
		const getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/jsonDocs/${jsonDocId}`;
		console.log('JsonDocs.componentDidMount: getUrl = ' + getUrl);
		let responsJson = null;

		const response = await fetch(getUrl);
		responsJson = await response.text();

		this.setState({
			item: responsJson,
		});
	}

	render() {
		const { item } = this.state;

		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					<ReactJson
						src={item}
						displayObjectSize={false}
						displayDataTypes={false}
						collapsed={1}
					/>
				</Container>
			</div>
		);
	}
}

export default JsonDocs;
