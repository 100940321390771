import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Const from '../Const';
import StatusError from './StatusError';
import {
	getLevelClass,
	checkResponseJson,
	checkResponseBody,
	loadPaginationConfig,
	queryInfoDefault,
	searchParamsToQueryInfo,
	queryInfoToSearchParams,
	queryInfoRowCountStr,
	formatEnum,
} from './Utils.js';
import authHeader from '../services/auth-header';
import Pagination from 'react-js-pagination';
import TransactionFilter from './TransactionFilter';
import Moment from 'react-moment';

class TransactionList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			modal: false,
			queryInfo: queryInfoDefault(),
			filterModal: {
				title: 'My Title',
				body: 'Hello',
				data: [],
				onHide: this.onFilterModalHide,
				onCancel: this.onFilterModalCancel,
				onValidate: this.onFilterModalValidate,
			},
		};
		this.remove = this.remove.bind(this);
		this.loadList = this.loadList.bind(this);
		this.onPageActiveChange = this.onPageActiveChange.bind(this);
		this.onListRefresh = this.onListRefresh.bind(this);

		this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
		this.onFilterModalCancel = this.onFilterModalCancel.bind(this);
		this.onFilterModalValidate = this.onFilterModalValidate.bind(
			this,
		);
		this.onFilterModalHide = this.onFilterModalHide.bind(this);

		this.paginationProps = loadPaginationConfig();
		this.paginationProps.onChange = this.onPageActiveChange;
	}

	async componentDidMount() {
		console.log('TransactionList.componentDidMount');

		const queryInfo = searchParamsToQueryInfo(
			this.props.browserSearchParams,
		);

		this.loadList(queryInfo);
	}
	async remove(transactionId) {

		if (!window.confirm('Delete this transaction?')) 
			return;

		const {merchantBasePath} = this.props;			

		const deleteUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/transactions/${transactionId}`;			
		console.log("TransactionList.remove: deleteUrl = " + deleteUrl);
	
		await fetch(
			deleteUrl, 
			{
				method: 'DELETE',
				headers: Object.assign(
					authHeader(), 
					{
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				)
			}
		).then(() => {
			let updatedTransactionsList = [...this.state.items].filter(i => i.transactionId !== transactionId);
			this.setState({items: updatedTransactionsList});

		});
	}

	onListRefresh() {
		this.loadList(this.state.queryInfo);
	}

	onPageActiveChange(pageActive) {
		console.log(
			'TransactionList.onPageActiveChange: pageActive = ' +
				pageActive,
		);

		if (!pageActive) return;

		const { queryInfo } = this.state;

		queryInfo.paginationInfo.pageActive = pageActive;

		this.loadList(queryInfo);
	}

	onFilterButtonClick() {
		console.log('TransactionList.onFilterButtonClick');

		this.setState({
			modal: true,
		});
	}

	onFilterModalValidate(filterInfo) {
		if (!filterInfo) return;

		const { queryInfo } = this.state;
		queryInfo.filterInfo = filterInfo;

		this.setState({
			modal: false,
			queryInfo: queryInfo,
		});

		this.loadList(queryInfo);
	}

	onFilterModalCancel() {
		console.log('TransactionList.onFilterModalCancel');

		this.setState({
			modal: false,
		});
	}

	onFilterModalHide(msg) {
		console.log('TransactionList.onFilterModalHide: msg = ' + msg);
	}

	async loadList(queryInfo) {
		console.log('TransactionList.loadList');

		this.setState({
			isLoading: true,
		});

		let queryComponent = '';
		let urlSearchParams = queryInfoToSearchParams(queryInfo);
		if (urlSearchParams)
			queryComponent = '?' + urlSearchParams.toString();

		const { merchantBasePath } = this.props;

		let getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/transactions${queryComponent}`;

		console.log('TransactionList.loadList: getUrl = ' + getUrl);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['transactions']);

				if (drhRsp.statusError) {
					console.error(
						'TransactionList.loadList: json = ' +
							JSON.stringify(jsonBody),
					);
				} else {
					//console.log("TransactionList.loadList: json = " + JSON.stringify(jsonBody));
				}
			});
		}

		if (drhRsp.body) {
			const { paginationInfo } = drhRsp.body;

			if (paginationInfo) {
				// when defined, server-returned PaginationInfo can completely override the existing one
				queryInfo.paginationInfo = paginationInfo;

				console.log(
					`TransactionList.loadList: paginationInfo = ${JSON.stringify(
						paginationInfo,
					)}`,
				);

				this.paginationProps.activePage =
					paginationInfo.pageActive;
				this.paginationProps.itemsCountPerPage =
					paginationInfo.pageSize;
				this.paginationProps.totalItemsCount =
					paginationInfo.rowCountInTotal;
			}
		}

		this.setState({
			items: drhRsp.body ? drhRsp.body.transactions : null,
			queryInfo: queryInfo,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	render() {
		const {
			items,
			httpInfo,
			statusError,
			isLoading,
			filterModal,
			queryInfo,
			modal,
		} = this.state;
		const { merchantBasePath } = this.props;

		console.log(`TransactionList.render: modal:${modal}`);

		if (isLoading) {
			return <p>Loading...</p>;
		}

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		const itemList = items.map((item) => {
			const viewLink = `/api/rest/merchants/${merchantBasePath}/transactions/${item.transactionId}`;

			return (
				<tr key={item.transactionId}>
					<td title={item.transactionId} className="hexIdGray">
						{item.transactionId.substring(0, 4) + '\u2026'}
					</td>
					<td className="valMainHex">
						{item.transactionShortId}
					</td>
					<td>
						<b>{item.crmTokenValue}</b>
					</td>
					<td
						className={getLevelClass(
							item.processingToVault.salesReceipt,
						)}
						style={{
							width: 40,
							textAlign: 'left',
						}}
					>
						{' '}
						{formatEnum(item.processingToVault.salesReceipt)}
					</td>

					<td
						className={getLevelClass(
							item.processingToVault.cardholderReceipt,
						)}
						style={{
							width: 40,
							textAlign: 'left',
						}}
					>
						{formatEnum(
							item.processingToVault.cardholderReceipt,
						)}
					</td>

					<td>
						<Moment format="YYYY-MM-DD HH:mm:ss">
							{item.timeReceived}
						</Moment>
					</td>

					<ButtonGroup>
						<Button
							size="sm"
							color="secondary"
							tag={Link}
							to={viewLink}
						>
							View
						</Button>

						<div>&nbsp;</div>
						<Button size="sm" color="danger" onClick={() => this.remove(item.transactionId)}>Delete</Button>

				
					</ButtonGroup>
				</tr>
			);
		});

		const rowCountStr = queryInfoRowCountStr(queryInfo);

		return (
			<div>
				<Container>
					<Button
						size="sm"
						color="secondary"
						title="Refresh"
						onClick={() => this.onListRefresh()}
					>
						{'\u21BB'}
					</Button>
					&nbsp;&nbsp;
					<Button
						size="sm"
						color="secondary"
						onClick={() => this.onFilterButtonClick()}
					>
						Filters...
					</Button>
					<span style={{ color: 'silver', marginLeft: '20px' }}>
						{rowCountStr}
					</span>
					<br />
					<br />
					<Pagination {...this.paginationProps} />
					<TransactionFilter
						show={this.state.modal}
						title={filterModal.title}
						body={filterModal.body}
						filterInfo={queryInfo.filterInfo}
						onHide={filterModal.onHide.bind(this)}
						onCancel={filterModal.onCancel.bind(this)}
						onValidate={filterModal.onValidate.bind(this)}
					/>
					<Table className="mt-4" width="1270px">
						<thead>
							<tr>
								<th width="60px">ID</th>
								<th width="60px">Short ID</th>
								<th width="110px">CRM Token</th>
								<th width="110px">Sales Receipt</th>
								<th width="110px">Cardholder Receipt</th>
								<th width="70px">Time Received</th>
								<th width="120px">Actions</th>
							</tr>
						</thead>
						<tbody>{itemList}</tbody>
					</Table>
					<br />
				</Container>
			</div>
		);
	}
}

export default TransactionList;
