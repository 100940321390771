import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import * as Const from '../Const';
import StatusError from './StatusError';
import { checkResponseJson, checkResponseBody } from './Utils.js';
import authHeader from '../services/auth-header';
import DepositReservationList from './DepositReservationList';

class DepositReservation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'DepositReservation';

		const { vaultBasePath } = this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}`;
		console.log(
			'DepositReservation.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['status']);
				console.log(
					'DepositReservation.componentDidMount: json = ' +
						JSON.stringify(jsonBody),
				);

				if (drhRsp.statusError) {
					console.error(
						'DepositReservation.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			body: drhRsp.body,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	render() {
		const { body, httpInfo, statusError, isLoading } = this.state;
		const { vaultBasePath } = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>Vault Deposit Reservations</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		let browserSearchParams = null;

		if (this.props.location) {
			browserSearchParams = new URLSearchParams(
				this.props.location.search,
			);
			console.log(
				'DepositReservation.render: browserSearchParams = ' +
					browserSearchParams.toString(),
			);
		}
		const item = body.vault;

		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br />
					<h4>
						<b>Vault</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Vault ID</Col>
						<Col className="hexIdGray">{item.vaultId}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Name</Col>
						<Col className="valMain">{item.name}</Col>
					</Row>

					<br />
					<br />
					<h4>
						<b>Deposit Reservations</b>
					</h4>

					<DepositReservationList
						id="reservations"
						name="reservations"
						vaultBasePath={vaultBasePath}
						vaultId={item.vaultId}
					/>

					<br />
					<Button
						color="secondary"
						tag={Link}
						to={`/api/rest/vaults/${vaultBasePath}`}
					>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(DepositReservation);
