import React, { Component } from 'react';
import {  Container } from 'reactstrap';
import * as Const from '../Const';

class BinaryDocs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}
	async componentDidMount() {
		this.setState({ isLoading: true });

		const { merchantBasePath, binaryDocId } = this.props;

		const getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/binaryDocs/${binaryDocId}`;

		console.log('BinaryDocs.componentDidMount: getUrl = ' + getUrl);
		let response = null;
		await fetch(getUrl)
			.then((response) => response.blob())
			.then(
				(blob) =>
					new Promise((callback) => {
						let reader = new FileReader();
						reader.onload = function () {
							callback(this.result);
							response = reader.result;
						};

						reader.readAsDataURL(blob);
					}),
			);

		this.setState({
			item: response,
		});
	}

	render() {
		const { item } = this.state;

		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					<embed
						src={item}
						id="displayFile"
						alt="your pdf"
						width="900"
						height="800"
						style={{ borderStyle: 'solid' }}
						type="application/pdf"
					/>
				</Container>
			</div>
		);
	}
}

export default BinaryDocs;
