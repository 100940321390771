import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col, Table } from 'reactstrap';
import * as Const from '../Const';
import Moment from 'react-moment';

import StatusError from './StatusError';
import {
	getLevelClass,
	checkResponseJson,
	checkResponseBody,
	formatEnum,
} from './Utils.js';
import authHeader from '../services/auth-header';

class ReceiptDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
		this.goBack = this.goBack.bind(this);
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Receipt';

		const { vaultBasePath, receiptId } = this.props.match.params;
		const queryParams = new URLSearchParams(
			this.props.location.search,
		);

		let transactionId = null;
		let consentFlowId = null;

		if (queryParams) {
			transactionId = queryParams.get('transactionId');
			consentFlowId = queryParams.get('consentFlowId');
		}
		const getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}/receipts/${receiptId}`;
		console.log(
			'ReceiptDetail.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['status']);

				if (drhRsp.statusError) {
					console.error(
						'ReceiptDetail.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}
		let backLink = '/';
		if (transactionId) {
			// TODO: make it possible to go back to 'View Transaction'
			backLink = `/api/rest/merchants/${drhRsp.body.merchandAndVaultInfo.merchantBasePath}/transactions/${transactionId}  `;
		} else if (consentFlowId) {
			// TODO: make it possible to go back to 'View ConsentFlow'
			backLink = `/api/rest/vaults/${vaultBasePath}/consentFlows/${consentFlowId} `;
		} else {
			backLink = `/api/rest/vaults/${vaultBasePath}/receipts`;
		}
		this.setState({
			item: drhRsp.body,
			replayedFrom: drhRsp.body ? drhRsp.body.replayedFrom : null,
			backLink: backLink,
			transactionId: transactionId,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	goBack() {
		console.log('ReceiptDetail.goBack');
		this.props.history.goBack();
	}

	render() {
		const {
			item,
			backLink,
			httpInfo,
			statusError,
			isLoading,
			transactionId,
		} = this.state;
		const { vaultBasePath, receiptId } = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>{'View Receipt'}</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}
		const merchantBasePath =
			item.merchandAndVaultInfo.merchantBasePath;
		const transactionLink = `/api/rest/merchants/${merchantBasePath}/transactions/${item.transactionInfo.transactionId}
	 ?receiptId=${receiptId}`;
		const crmTokenId = item.customerInfo.crmTokenId;
		const crmLink = `/api/rest/merchants/${merchantBasePath}/crmTokens/${crmTokenId}?receiptId=${receiptId}`;

		const items = item.representations;
		const itemList = items.map((itemRepresentation) => {
			const viewLink = `/api/rest/vaults/${vaultBasePath}/receipts/${receiptId}/representations/${itemRepresentation.index}`;

			return (
				<tr key={itemRepresentation.index}>
					<td className="hexIdGray">
						{itemRepresentation.index}
					</td>
					<td>{itemRepresentation.mediaType}</td>
					<td
						className={getLevelClass(
							itemRepresentation.producedBy,
						)}
						style={{
							width: 40,
							textAlign: 'left',
						}}
					>
						{itemRepresentation.producedBy}
					</td>
					<td className="valMain">
						<b>
							{String(itemRepresentation.processingCompleted)}
						</b>
					</td>
					<td
						className={getLevelClass(
							itemRepresentation.vaultdepositing,
						)}
						style={{
							width: 40,
							textAlign: 'left',
						}}
						title={itemRepresentation.vaultdepositing}
					>
						{formatEnum(itemRepresentation.vaultdepositing)}
					</td>
					<td>
						<Button
							size="sm"
							color="secondary"
							tag={Link}
							to={viewLink}
						>
							View
						</Button>
					</td>
				</tr>
			);
		});
		const hashubDocHandling =
			item.hubDocHandling && item.hubDocHandling !== 'undefined';
		return (
			<div>
				<Container fluid style={{ paddingLeft: 40 }}>
					{title}
					<br />
					<h4>
						<b>Receipt Info</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Type</Col>
						<Col className="valMain">
							{item.receiptInfo.receiptType}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Receipt ID</Col>
						<Col className="hexIdGray">
							<b>{item.receiptInfo.receiptId}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time Created</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{item.receiptInfo.timeCreated}
							</Moment>
						</Col>
					</Row>

					<br />

					<br />

					{hashubDocHandling && (
						<>
							<h4>
								<b>Doc Handling (by Hubs)</b>
							</h4>
							{item.hubDocHandling.createdBy && (
								<>
									{' '}
									<Row className="rowSpace">
										<Col xs="2">Created by</Col>
										<Col className="hexIdGray">
											{item.hubDocHandling.createdBy}
										</Col>
									</Row>
								</>
							)}
							{item.hubDocHandling.createdAt && (
								<>
									<Row className="rowSpace">
										<Col xs="2">Created at</Col>
										<Col className="hexId">
											<Moment format="YYYY-MM-DD HH:mm:ss">
												{item.hubDocHandling.createdAt}
											</Moment>
										</Col>
									</Row>
								</>
							)}
							{item.hubDocHandling.updatedBy && (
								<>
									<Row className="rowSpace">
										<Col xs="2">Updated by</Col>
										<Col className="hexIdGray">
											{item.hubDocHandling.updatedBy}
										</Col>
									</Row>
								</>
							)}
							{item.hubDocHandling.updatedAt && (
								<>
									<Row className="rowSpace">
										<Col xs="2">Updated at</Col>
										<Col className="hexId">
											<Moment format="YYYY-MM-DD HH:mm:ss">
												{item.hubDocHandling.updatedAt}
											</Moment>
										</Col>
									</Row>
								</>
							)}
							<br />
							<br />
						</>
					)}
					<h4>
						<b>Transaction Info</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Transaction ID</Col>
						<Col className="hexId">
							{item.transactionInfo.transactionId}
							&nbsp; &nbsp;
							{!transactionId && (
								<Button
									color="secondary"
									tag={Link}
									target="_blank"
									size="sm"
									to={transactionLink}
								>
									{'\u3009'}
								</Button>
							)}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Short ID</Col>
						<Col className="valMainHex">
							{item.transactionInfo.transactionShortId}
						</Col>
					</Row>

					<br />

					<br />
					<h4>
						<b>Customer Info</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Customer Identified</Col>
						<Col className="valMain">
							{String(item.customerInfo.customerIdentified)}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">CRM Token ID</Col>
						<Col className="hexId">
							{item.customerInfo.crmTokenId}
							&nbsp; &nbsp;
							<Button
								color="secondary"
								tag={Link}
								target="_blank"
								size="sm"
								to={crmLink}
							>
								{'\u3009'}
							</Button>
						</Col>
					</Row>

					<br />
					<h4>
						<b>Merchant and Vault Info</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Merchant Name</Col>
						<Col className="valMain">
							{item.merchandAndVaultInfo.merchantName}
						</Col>
					</Row>

					<Row className="rowSpace">
						<Col xs="2">Vault Name</Col>
						<Col className="valMain">
							{String(item.merchandAndVaultInfo.vaultName)}
						</Col>
					</Row>

					<br />
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Representations</b>
						<div className="container">
							<Table className="mt-4" width="1270px">
								<thead>
									<tr>
										<th width="60px">#</th>
										<th width="60px">Media type</th>
										<th width="110px">Produced by</th>
										<th width="110px">Completed</th>
										<th width="110px">To Vault</th>
										<th width="120px">Actions</th>
									</tr>
								</thead>

								<tbody>{itemList}</tbody>
							</Table>
						</div>
					</Container>
					<br />
					<Button color="secondary" tag={Link} to={backLink}>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(ReceiptDetail);
