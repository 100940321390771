import React, { Component } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Const from '../Const';
import StatusError from './StatusError';
import {
	formatEnum,
	getLevelClass,
	checkResponseJson,
	checkResponseBody,
	loadPaginationConfig,
	queryInfoDefault,
	searchParamsToQueryInfo,
	queryInfoToSearchParams,
	queryInfoRowCountStr,
} from './Utils.js';
import authHeader from '../services/auth-header';
import Pagination from 'react-js-pagination';
import Moment from 'react-moment';
import HubInstanceFilter from './HubInstanceFilter';

class HubInstanceList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			modal: false,
			queryInfo: queryInfoDefault(),
			filterModal: {
				title: 'My Title',
				body: 'Hello',
				data: [],
				onHide: this.onFilterModalHide,
				onCancel: this.onFilterModalCancel,
				onValidate: this.onFilterModalValidate,
			},
		};

		this.loadList = this.loadList.bind(this);
		this.onPageActiveChange = this.onPageActiveChange.bind(this);
		this.onListRefresh = this.onListRefresh.bind(this);

		this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
		this.onFilterModalCancel = this.onFilterModalCancel.bind(this);
		this.onFilterModalValidate = this.onFilterModalValidate.bind(
			this,
		);
		this.onFilterModalHide = this.onFilterModalHide.bind(this);

		this.paginationProps = loadPaginationConfig();
		this.paginationProps.onChange = this.onPageActiveChange;
	}

	async componentDidMount() {
		console.log('HubInstanceList.componentDidMount');

		const queryInfo = searchParamsToQueryInfo(
			this.props.browserSearchParams,
		);

		this.loadList(queryInfo);
	}

	onListRefresh() {
		this.loadList(this.state.queryInfo);
	}

	onPageActiveChange(pageActive) {
		console.log(
			'HubInstanceList.onPageActiveChange: pageActive = ' +
				pageActive,
		);

		if (!pageActive) return;

		const { queryInfo } = this.state;

		queryInfo.paginationInfo.pageActive = pageActive;

		this.loadList(queryInfo);
	}

	onFilterButtonClick() {
		console.log('HubInstanceList.onFilterButtonClick');

		this.setState({
			modal: true,
		});
	}

	onFilterModalValidate(filterInfo) {
		if (!filterInfo) return;

		const { queryInfo } = this.state;
		queryInfo.filterInfo = filterInfo;

		this.setState({
			modal: false,
			queryInfo: queryInfo,
		});

		this.loadList(queryInfo);
	}

	onFilterModalCancel() {
		console.log('HubInstanceList.onFilterModalCancel');

		this.setState({
			modal: false,
		});
	}

	onFilterModalHide(msg) {
		console.log('HubInstanceList.onFilterModalHide: msg = ' + msg);
	}

	async loadList(queryInfo) {
		console.log('HubInstanceList.loadList');

		this.setState({
			isLoading: true,
		});

		let queryComponent = '';
		let urlSearchParams = queryInfoToSearchParams(queryInfo);
		if (urlSearchParams)
			queryComponent = '?' + urlSearchParams.toString();

		let getUrl = `${Const.API_BASE_PATH}/common/hubInstances${queryComponent}`;

		console.log('HubInstanceList.loadList: getUrl = ' + getUrl);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['hubInstances']);

				if (drhRsp.statusError) {
					console.error(
						'HubInstanceList.loadList: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		if (drhRsp.body) {
			const { paginationInfo } = drhRsp.body;

			if (paginationInfo) {
				// when defined, server-returned PaginationInfo can completely override the existing one
				queryInfo.paginationInfo = paginationInfo;

				console.log(
					`HubInstanceList.loadList: paginationInfo = ${JSON.stringify(
						paginationInfo,
					)}`,
				);

				this.paginationProps.activePage =
					paginationInfo.pageActive;
				this.paginationProps.itemsCountPerPage =
					paginationInfo.pageSize;
				this.paginationProps.totalItemsCount =
					paginationInfo.rowCountInTotal;
			}
		}

		this.setState({
			items: drhRsp.body ? drhRsp.body.hubInstances : null,
			queryInfo: queryInfo,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	render() {
		const {
			items,
			httpInfo,
			statusError,
			isLoading,
			filterModal,
			queryInfo,
			modal,
		} = this.state;

		console.log(`HubInstanceList.render: modal:${modal}`);

		if (isLoading) {
			return <p>Loading...</p>;
		}

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		const itemList = items.map((item) => {
			const viewLink = `/api/rest/common/hubInstances/${item.hubInstanceId}`;
			const hasMemory =
				item.pctMemoryUsed && item.pctMemoryUsed !== 'undefined';

			return (
				<tr key={item.hubInstanceId}>
					<td title={item.hubInstanceId} className="valMain">
						{item.hubInstanceId}
					</td>
					<td className="valMainText">
						<b>{item.region}</b>
					</td>
					<td>{item.machineSpecs}</td>
					<td className="state">
						<Moment format="YYYY-MM-DD HH:mm:ss">
							{item.timeLastRunning}
						</Moment>
					</td>
					<td
						className={getLevelClass(item.runningState)}
						style={{ width: 40, textAlign: 'left' }}
						title={item.runningState}
					>
						{formatEnum(item.runningState)}
					</td>
					<td>
						{hasMemory && <b>{item.pctMemoryUsed + '%'}</b>}
					</td>
					<td>
						<Button
							size="sm"
							color="secondary"
							tag={Link}
							to={viewLink}
						>
							View
						</Button>
					</td>
				</tr>
			);
		});

		const rowCountStr = queryInfoRowCountStr(queryInfo);

		return (
			<div>
				<Container>
					<Button
						size="sm"
						color="secondary"
						title="Refresh"
						onClick={() => this.onListRefresh()}
					>
						{'\u21BB'}
					</Button>
					&nbsp;&nbsp;
					<Button
						size="sm"
						color="secondary"
						onClick={() => this.onFilterButtonClick()}
					>
						Filters...
					</Button>
					<span style={{ color: 'silver', marginLeft: '20px' }}>
						{rowCountStr}
					</span>
					<br />
					<br />
					<Pagination {...this.paginationProps} />
					<HubInstanceFilter
						show={this.state.modal}
						title={filterModal.title}
						body={filterModal.body}
						filterInfo={queryInfo.filterInfo}
						onHide={filterModal.onHide.bind(this)}
						onCancel={filterModal.onCancel.bind(this)}
						onValidate={filterModal.onValidate.bind(this)}
					/>
					<Table className="mt-4" width="1270px">
						<thead>
							<tr>
								<th width="60px">Hub Instance ID</th>
								<th width="110px">Region</th>
								<th width="110px">Specs</th>
								<th width="110px">Time Last Running</th>
								<th width="110px">Running State</th>
								<th width="110px">Memory usage</th>
								<th width="120px">Actions</th>
							</tr>
						</thead>
						<tbody>{itemList}</tbody>
					</Table>
					<br />
				</Container>
			</div>
		);
	}
}

export default HubInstanceList;
