import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { checkResponseJson, checkResponseBody} from './Utils.js';
import authHeader from "../services/auth-header";

const Const = require("../Const");

class MerchantList extends Component {

	constructor(props) {
		super(props);
		this.state = {items: [], isLoading: true};
	}

	async componentDidMount() {
		this.setState({isLoading: true});

        document.title = "Merchants";
		const {vaultBasePath} = this.props;

		const getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}/merchants`;
		console.log("MerchantList.componentDidMount: getUrl = " + getUrl);

		const response = await fetch(
			getUrl,
			{
				method: 'GET',
				headers: authHeader()
			});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError)  {
			await response.json().then((jsonBody) => {
				console.log("MerchantList.componentDidMount: json = " + JSON.stringify(jsonBody));

				checkResponseBody(jsonBody, drhRsp, ['merchants']);

				if (drhRsp.statusError) {
					console.error("MerchantList.componentDidMount: json = " + JSON.stringify(jsonBody));
				}
			});
		}

		this.setState({ 
			isLoading: false,
			items: drhRsp.body ? drhRsp.body.merchants : null,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
		});	
	}

	render() {
		const {items, isLoading} = this.state;
 
		if (isLoading) {
			return <p>Loading...</p>;
		}
	 
		const itemList = items.map(item => {

			const viewLink = `/api/rest/merchants/${item.basePath}`;

			return (
				<tr key={item.merchantId}>
					<td className="hexIdGray">{item.merchantId}</td>
					<td className="valMain fitwidth">{item.name}</td>
					<td className="fitwidth">/{item.basePath}</td>
					<td>
 							<Button size="sm" color="secondary" tag={Link} to={viewLink}>View</Button>
 					</td>
				</tr>
			)
		});

		return (
			
			<div>
				<Container>
				<h2>Merchants</h2>

					<Table className="mt-4" width="1070px">
						<thead>
							<tr>
								<th width="120px">ID</th>
								<th width="170px">Name</th>
								<th width="170px">Base Path</th>
								<th width="170px">Actions</th>
							</tr>
						</thead>
						<tbody>
							{itemList}
						</tbody>
					</Table>
				</Container>
			</div>
		);
	}
}

export default MerchantList;