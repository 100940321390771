import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col, Table } from 'reactstrap';
import * as Const from '../Const';
import Moment from 'react-moment';

import StatusError from './StatusError';
import {
	formatEnum,
	getLevelClass,
	checkResponseJson,
	checkResponseBody,
} from './Utils.js';
import authHeader from '../services/auth-header';

class ConsentFlowDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
		this.goBack = this.goBack.bind(this);
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Consent Flow';

		const {
			vaultBasePath,
			consentFlowId,
		} = this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}/consentFlows/${consentFlowId}`;
		console.log(
			'ConsentFlowDetail.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['status']);

				if (drhRsp.statusError) {
					console.error(
						'ConsentFlowDetail.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			item: drhRsp.body,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	goBack() {
		console.log('ConsentFlowDetail.goBack');
		this.props.history.goBack();
	}

	render() {
		const { item, httpInfo, statusError, isLoading } = this.state;
		const {
			vaultBasePath,
			consentFlowId,
		} = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>{'View Consent Flow'}</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}
		let backLink = `/api/rest/vaults/${vaultBasePath}/consentFlows`;
		const merchantBasePath = item.flowInfo.merchantName.toLocaleLowerCase();

		let viewLink = `/api/rest/merchants/${merchantBasePath}/crmTokens/${item['step1-WebTokenization'].crmTokenId}?consentFlowId=${consentFlowId}`;

		let bearerToken = item['step5-TokenResponse'].bearerToken || {};

		const items =
			item['step8-GhostReceiptsDeposit'].unghostedReceipts;
		const itemList = items.map((receipt) => {
			const viewReceiptLink = `/api/rest/vaults/${vaultBasePath}/receipts/${receipt.receiptId}?consentFlowId=${consentFlowId}`;

			return (
				<tr key={receipt.receiptId}>
					<td className="hexIdGray">
						{receipt.receiptId.substring(0, 4) + '\u2026'}
					</td>
					<td className="valMainHex">
						{receipt.transactionShortId}
					</td>
					<td>
						<Moment format="YYYY-MM-DD HH:mm:ss">
							{receipt.timeOriginallyPlanned}
						</Moment>
					</td>
					<td
						className={getLevelClass(receipt.availability)}
						style={{
							width: 40,
							textAlign: 'left',
						}}
						title={receipt.availability}
					>
						{formatEnum(receipt.availability)}
					</td>

					<td>
						<Button
							size="sm"
							color="secondary"
							tag={Link}
							to={viewReceiptLink}
						>
							View
						</Button>
					</td>
				</tr>
			);
		});
		const hashubDocHandling  =
		item.hubDocHandling  &&
		item.hubDocHandling  !== 'undefined';
		return (
			<div>
				<Container fluid style={{ paddingLeft: 40 }}>
					{title}
					<br />
					<h4>
						<b>Flow Info</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Content Flow ID</Col>
						<Col className="hexIdGray">
							<b>{item.flowInfo.consentFlowId}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Vault</Col>
						<Col className="valMain">
							{item.flowInfo.vaultName}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Merchant</Col>
						<Col className="valMain">
							{item.flowInfo.merchantName}
						</Col>
					</Row>

					<Row className="rowSpace">
						<Col xs="2">Time started</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{item.flowInfo.timeStarted}
							</Moment>
						</Col>
					</Row>
					{item.flowInfo.timeUpdated && (
						<Row className="rowSpace">
							<Col xs="2">Last updated</Col>
							<Col className="hexId">
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{item.flowInfo.timeUpdated}
								</Moment>
							</Col>
						</Row>
					)}

					<Row className="rowSpace">
						<Col xs="2">Last step completed </Col>
						<Col
							className={getLevelClass(
								item.flowInfo.lastStepCompleted,
							)}
							style={{
								width: 40,
								textAlign: 'left',
							}}
							title={item.flowInfo.lastStepCompleted}
						>
							{formatEnum(item.flowInfo.lastStepCompleted)}
						</Col>
					</Row>

					<Row className="rowSpace">
						<Col xs="2">Last step outcome </Col>
						<Col
							className={getLevelClass(
								item.flowInfo.lastStepOutcome,
							)}
							style={{
								width: 40,
								textAlign: 'left',
							}}
							title={item.flowInfo.lastStepOutcome}
						>
							<b>
								{formatEnum(item.flowInfo.lastStepOutcome)}
							</b>
						</Col>
					</Row>
					<br />
					
					{hashubDocHandling && (
						<>
							<h4>
								<b>Doc Handling (by Hubs)</b>
							</h4>
							{item.hubDocHandling.createdBy && (<>	<Row className="rowSpace">
								<Col xs="2">Created  by</Col>
								<Col className="hexIdGray">
									{item.hubDocHandling.createdBy}
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.createdAt && (<>
							<Row className="rowSpace">
								<Col xs="2">Created  at</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{item.hubDocHandling.createdAt}
									</Moment>
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.updatedBy && (<>
							<Row className="rowSpace">
								<Col xs="2">Updated by</Col>
								<Col className="hexIdGray">
									{item.hubDocHandling.updatedBy}
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.updatedAt && (<>
							<Row className="rowSpace">
								<Col xs="2">Updated at</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{item.hubDocHandling.updatedAt}
									</Moment>
								</Col>
							</Row>
							</>)}
							<br />
							<br />
						</>
					)}
					<h4>
						<b>1. Web tokenization</b>
					</h4>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Status</b>
						<Row className="rowSpace">
							<Col xs="2">Completion </Col>
							<Col
								className={getLevelClass(
									item['step1-WebTokenization'].status
										.completion,
								)}
								style={{
									width: 40,
									textAlign: 'left',
								}}
								title={
									item['step1-WebTokenization'].status
										.completion
								}
							>
								<b>
									{formatEnum(
										item['step1-WebTokenization'].status
											.completion,
									)}
								</b>
							</Col>
						</Row>
						{!item['step1-WebTokenization'].status
							.timeCompleted && (
							<Row className="rowSpace">
								<Col xs="2">Time Completed</Col>
								<Col className="hexId">
									{
										<Moment format="YYYY-MM-DD HH:mm:ss">
											{
												item['step1-WebTokenization']
													.status.timeCompleted
											}
										</Moment>
									}
								</Col>
							</Row>
						)}
						{item['step1-WebTokenization'].status
							.errorCause && (
							<Row className="rowSpace">
								<Col xs="2">Error Cause </Col>
								<Col
									className={getLevelClass(
										item['step1-WebTokenization'].status
											.errorCause,
									)}
									style={{
										width: 40,
										textAlign: 'left',
									}}
									title={
										item['step1-WebTokenization'].status
											.errorCause
									}
								>
									<b>
										{
											item['step1-WebTokenization'].status
												.errorCause
										}
									</b>
								</Col>
							</Row>
						)}
						<br />
						<br />

						<b>Info</b>
						<Row className="rowSpace">
							<Col xs="2">Customer ID </Col>
							<Col className="hexId">
								{item['step1-WebTokenization'].customerId}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time tokenized</Col>
							<Col className="hexId">
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{
										item['step1-WebTokenization']
											.timeTokenized
									}
								</Moment>
							</Col>
						</Row>

						<Row className="rowSpace">
							<Col xs="2">CRM Token ID</Col>
							<Col className="hexId">
								{item['step1-WebTokenization'].crmTokenId}
								&nbsp; &nbsp;
								<Button
									color="secondary"
									tag={Link}
									target="_blank"
									size="sm"
									to={viewLink}
								>
									{'\u3009'}
								</Button>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Token value</Col>
							<Col className="valMain">
								<b>
									{
										item['step1-WebTokenization']
											.crmTokenValue
									}
								</b>
							</Col>
						</Row>
					</Container>

					<br />

					<br />
					<h4>
						<b>2. Authorization Request</b>
					</h4>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Status</b>
						<Row className="rowSpace">
							<Col xs="2">Completion </Col>
							<Col
								className={getLevelClass(
									item['step2-AuthorizationRequest'].status
										.completion,
								)}
								style={{
									width: 40,
									textAlign: 'left',
								}}
								title={
									item['step2-AuthorizationRequest'].status
										.completion
								}
							>
								<b>
									{formatEnum(
										item['step2-AuthorizationRequest']
											.status.completion,
									)}
								</b>
							</Col>
						</Row>
						{item['step2-AuthorizationRequest'].status
							.timeCompleted && (
							<Row className="rowSpace">
								<Col xs="2">Time Completed</Col>
								<Col className="hexId">
									{
										<Moment format="YYYY-MM-DD HH:mm:ss">
											{
												item['step2-AuthorizationRequest']
													.status.timeCompleted
											}
										</Moment>
									}
								</Col>
							</Row>
						)}
						{item['step2-AuthorizationRequest'].status
							.errorCause && (
							<Row className="rowSpace">
								<Col xs="2">Error Cause </Col>
								<Col
									className={getLevelClass(
										item['step2-AuthorizationRequest']
											.status.errorCause,
									)}
									style={{
										width: 40,
										textAlign: 'left',
									}}
									title={
										item['step2-AuthorizationRequest']
											.status.errorCause
									}
								>
									<b>
										{
											item['step2-AuthorizationRequest']
												.status.errorCause
										}
									</b>
								</Col>
							</Row>
						)}{' '}
						<br />
						<br />
						<b>Payload</b>
						<Row className="rowSpace">
							<Col xs="2">State sent </Col>
							<Col className="hexId">
								{item['step2-AuthorizationRequest'].stateSent}
							</Col>
							<Col xs="2"> </Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Code challenge </Col>

							<Col className="hexId">
								{
									item['step2-AuthorizationRequest']
										.codeChallenge
								}
							</Col>
						</Row>
						<br />
						<br />
						<h4>
							<b>3. Authorization Response</b>
						</h4>
					</Container>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Status</b>
						<Row className="rowSpace">
							<Col xs="2">Completion </Col>
							{item['step3-AuthorizationResponse'].status && (
								<Col
									className={getLevelClass(
										item['step3-AuthorizationResponse']
											.status &&
											item['step3-AuthorizationResponse']
												.status.completion,
									)}
									style={{
										width: 40,
										textAlign: 'left',
									}}
									title={
										item['step3-AuthorizationResponse']
											.status &&
										item['step3-AuthorizationResponse']
											.status.completion
									}
								>
									{item['step3-AuthorizationResponse'].status
										.completion && (
										<b>
											{item['step3-AuthorizationResponse']
												.status &&
												formatEnum(
													item[
														'step3-AuthorizationResponse'
													].status.completion,
												)}
										</b>
									)}
								</Col>
							)}
						</Row>
						{item['step3-AuthorizationResponse'].status && (
							<Row className="rowSpace">
								<Col xs="2">Time Completed</Col>

								<Col className="hexId">
									{item['step3-AuthorizationResponse']
										.status && (
										<Moment format="YYYY-MM-DD HH:mm:ss">
											{
												item[
													'step3-AuthorizationResponse'
												].status.timeUpdated
											}
										</Moment>
									)}
								</Col>
							</Row>
						)}
						{item['step3-AuthorizationResponse'].status
							.errorCause && (
							<Row className="rowSpace">
								<Col xs="2">Error Cause </Col>
								<Col
									className={getLevelClass(
										item['step3-AuthorizationResponse']
											.status &&
											item['step3-AuthorizationResponse']
												.status.errorCause,
									)}
									style={{
										width: 40,
										textAlign: 'left',
									}}
									title={
										item['step3-AuthorizationResponse']
											.status &&
										item['step3-AuthorizationResponse']
											.status.errorCause
									}
								>
									<b>
										{item['step3-AuthorizationResponse']
											.status &&
											item['step3-AuthorizationResponse']
												.status.errorCause}
									</b>
								</Col>
							</Row>
						)}
						<br />
						<br />
						<b>Payload</b>
						<Row className="rowSpace">
							<Col xs="2">State received</Col>
							<Col className="hexId">
								{item['step3-AuthorizationResponse']
									.stateReceived &&
									item['step3-AuthorizationResponse']
										.stateReceived}
							</Col>
							<Col xs="2"> </Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Authorization code </Col>

							<Col className="hexId">
								{item['step3-AuthorizationResponse']
									.authorizationCode &&
									item['step3-AuthorizationResponse']
										.authorizationCode}
							</Col>
						</Row>
					</Container>

					<br />

					<br />
					<h4>
						<b>4. Token Request</b>
					</h4>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Status</b>
						<Row className="rowSpace">
							<Col xs="2">Completion </Col>
							<Col
								className={getLevelClass(
									item['step4-TokenRequest'].status
										.completion,
								)}
								style={{
									width: 40,
									textAlign: 'left',
								}}
								title={
									item['step4-TokenRequest'].status
										.completion
								}
							>
								<b>
									{formatEnum(
										item['step4-TokenRequest'].status
											.completion,
									)}
								</b>
							</Col>
						</Row>
						{item['step4-TokenRequest'].status
							.timeCompleted && (
							<Row className="rowSpace">
								<Col xs="2">Time Completed</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{
											item['step4-TokenRequest'].status
												.timeCompleted
										}
									</Moment>
								</Col>
							</Row>
						)}
						{item['step4-TokenRequest'].status.errorCause && (
							<Row className="rowSpace">
								<Col xs="2">Error Cause </Col>
								<Col
									className={getLevelClass(
										item['step4-TokenRequest'].status
											.errorCause,
									)}
									style={{
										width: 40,
										textAlign: 'left',
									}}
									title={
										item['step4-TokenRequest'].status
											.errorCause
									}
								>
									<b>
										{
											item['step4-TokenRequest'].status
												.errorCause
										}
									</b>
								</Col>
							</Row>
						)}
						<br />
						<br />

						<b>Payload</b>
						<Row className="rowSpace">
							<Col xs="2">Code verifier</Col>
							<Col className="hexId">
								{item['step4-TokenRequest'].codeVerifier}
							</Col>
						</Row>
					</Container>

					<br />

					<br />
					<h4>
						<b>5. Token Response</b>
					</h4>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Status</b>
						<Row className="rowSpace">
							<Col xs="2">Completion </Col>
							<Col
								className={getLevelClass(
									item['step5-TokenResponse'].status
										.completion,
								)}
								style={{
									width: 40,
									textAlign: 'left',
								}}
								title={
									item['step5-TokenResponse'].status
										.completion
								}
							>
								<b>
									{formatEnum(
										item['step5-TokenResponse'].status
											.completion,
									)}
								</b>
							</Col>
						</Row>
						{item['step5-TokenResponse'].status
							.timeCompleted && (
							<Row className="rowSpace">
								<Col xs="2">Time Completed</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{
											item['step5-TokenResponse'].status
												.timeCompleted
										}
									</Moment>
								</Col>
							</Row>
						)}
						{item['step5-TokenResponse'].status.errorCause && (
							<Row className="rowSpace">
								<Col xs="2">Error Cause </Col>
								<Col
									className={getLevelClass(
										item['step5-TokenResponse'].status
											.errorCause,
									)}
									style={{
										width: 40,
										textAlign: 'left',
									}}
									title={
										item['step5-TokenResponse'].status
											.errorCause
									}
								>
									<b>
										{' '}
										{
											item['step5-TokenResponse'].status
												.errorCause
										}
									</b>
								</Col>
							</Row>
						)}
						<br />
						<br />
						<b>Payload</b>
						<Row className="rowSpace">
							<Col xs="2">Access token</Col>
							<Col className="hexId">
								{bearerToken.access_token}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Expires in</Col>
							<Col>
								<b>{bearerToken.expires_in}</b>
							</Col>
						</Row>{' '}
						<Row className="rowSpace">
							<Col xs="2">Scope</Col>
							<Col>
								<b>{bearerToken.scope}</b>
							</Col>
						</Row>{' '}
						<Row className="rowSpace">
							<Col xs="2">Token type</Col>
							<Col>
								<b>{bearerToken.token_type}</b>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time Issued</Col>
							<Col className="hexId">
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{bearerToken.timeIssued}
								</Moment>
							</Col>
						</Row>
						{bearerToken.timeOfExpiry && (
							<Row className="rowSpace">
								<Col xs="2">Time of expiry </Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{bearerToken.timeOfExpiry}
									</Moment>
								</Col>
							</Row>
						)}
					</Container>
					<br />

					<br />

					<h4>
						<b>6. Subscription Request</b>
					</h4>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Status</b>
						<Row className="rowSpace">
							<Col xs="2">Completion </Col>
							<Col
								className={getLevelClass(
									item['step6-SubscriptionRequest'].status
										.completion,
								)}
								style={{
									width: 40,
									textAlign: 'left',
								}}
								title={
									item['step6-SubscriptionRequest'].status
										.completion
								}
							>
								<b>
									{' '}
									{formatEnum(
										item['step6-SubscriptionRequest'].status
											.completion,
									)}
								</b>
							</Col>
						</Row>
						{item['step6-SubscriptionRequest'].status
							.timeCompleted && (
							<Row className="rowSpace">
								<Col xs="2">Time Completed</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{
											item['step6-SubscriptionRequest']
												.status.timeCompleted
										}
									</Moment>
								</Col>
							</Row>
						)}
					</Container>

					<br />

					<br />
					<h4>
						<b>7. Subscription Response</b>
					</h4>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Status</b>
						<Row className="rowSpace">
							<Col xs="2">Completion </Col>
							<Col
								className={getLevelClass(
									item['step7-SubscriptionResponse'].status
										.completion,
								)}
								style={{
									width: 40,
									textAlign: 'left',
								}}
								title={
									item['step7-SubscriptionResponse'].status
										.completion
								}
							>
								<b>
									{formatEnum(
										item['step7-SubscriptionResponse']
											.status.completion,
									)}
								</b>
							</Col>
						</Row>
						{item['step7-SubscriptionResponse'].status
							.timeCompleted && (
							<Row className="rowSpace">
								<Col xs="2">Time Completed</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{
											item['step7-SubscriptionResponse']
												.status.timeCompleted
										}
									</Moment>
								</Col>
							</Row>
						)}
						<br />
						<br />

						<b>Payload</b>
						<Row className="rowSpace">
							<Col xs="2">Subscription ID</Col>
							<Col className="hexId">
								{
									item['step7-SubscriptionResponse']
										.subscriptionId
								}
							</Col>
						</Row>

						<br />

						<br />
					</Container>
					<br />

					<br />
					<h4>
						<b>8. Ghost Receipts Deposit</b>
					</h4>

					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Status</b>
						<Row className="rowSpace">
							<Col xs="2">Completion </Col>
							<Col
								className={getLevelClass(
									item['step8-GhostReceiptsDeposit'].status
										.completion,
								)}
								style={{
									width: 40,
									textAlign: 'left',
								}}
								title={
									item['step8-GhostReceiptsDeposit'].status
										.completion
								}
							>
								<b>
									{' '}
									{formatEnum(
										item['step8-GhostReceiptsDeposit']
											.status.completion,
									)}
								</b>
							</Col>
						</Row>
						{item['step8-GhostReceiptsDeposit'].status
							.timeCompleted && (
							<Row className="rowSpace">
								<Col xs="2">Time Completed</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{
											item['step8-GhostReceiptsDeposit']
												.status.timeCompleted
										}
									</Moment>
								</Col>
							</Row>
						)}
					</Container>
					<br />
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Unghosted receipts</b>
						<div className="container">
							<Table className="mt-4" width="1270px">
								<thead>
									<tr>
										<th width="60px">ID</th>
										<th width="60px">
											Transaction Short ID
										</th>
										<th width="110px">
											Originally planned time
										</th>
										<th width="110px">
											Post-unghost availability to vault
										</th>
										<th width="120px">Actions</th>
									</tr>
								</thead>

								<tbody>{itemList}</tbody>
							</Table>
						</div>
					</Container>
					<br />
					<Button color="secondary" tag={Link} to={backLink}>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(ConsentFlowDetail);
