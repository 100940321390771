import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import * as Const from '../Const';
import Moment from 'react-moment';
import StatusError from './StatusError';
import { checkResponseJson, checkResponseBody } from './Utils.js';

class VaultConfiguration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Configuration';

		const { vaultBasePath } = this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}/configuration`;
		console.log(
			'VaultConfiguration.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, [
					'vaultId',
					'configuration',
				]);

				if (drhRsp.statusError) {
					console.error(
						'VaultConfiguration.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			configuration: drhRsp.body.configuration,
			item: drhRsp.body,
			securityMaterial: drhRsp.body.securityMaterial,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	render() {
		const {
			configuration,
			securityMaterial,
			item,
			httpInfo,
			statusError,
			isLoading,
		} = this.state;
		const { vaultBasePath } = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>{'Vault Configuration'}</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}
		const hashubDocHandling  =
		item.hubDocHandling  &&
		item.hubDocHandling  !== 'undefined';
		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br />
					<h4>
						<b>Vault</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Vault ID</Col>
						<Col className="hexIdGray">{item.vaultId}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Name</Col>
						<Col className="valMain">{item.name}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Base Path</Col>
						<Col>{'/' + item.basePath}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Doc version</Col>
						<Col>{item.version}</Col>
					</Row>
					<br />
					<br />
					{hashubDocHandling && (
						<>
							<h4>
								<b>Doc Handling (by Hubs)</b>
							</h4>
							{item.hubDocHandling.createdBy && (<>	<Row className="rowSpace">
								<Col xs="2">Created  by</Col>
								<Col className="hexIdGray">
									{item.hubDocHandling.createdBy}
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.createdAt && (<>
							<Row className="rowSpace">
								<Col xs="2">Created  at</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{item.hubDocHandling.createdAt}
									</Moment>
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.updatedBy && (<>
							<Row className="rowSpace">
								<Col xs="2">Updated by</Col>
								<Col className="hexIdGray">
									{item.hubDocHandling.updatedBy}
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.updatedAt && (<>
							<Row className="rowSpace">
								<Col xs="2">Updated at</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{item.hubDocHandling.updatedAt}
									</Moment>
								</Col>
							</Row>
							</>)}
							<br />
							<br />
						</>
					)}
					<h4>
						<b>Configuration</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Load If Dts Localhost</Col>
						<Col className="valMain">
							{String(configuration.loadIfDtsLocalhost)}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Load If Dts Cloud</Col>
						<Col className="valMain">
							{String(configuration.loadIfDtsCloud)}
						</Col>
					</Row>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Requests Policy</b>
						<Row className="rowSpace">
							<Col xs="2">Max Requests Per Time Window</Col>
							<Col className="valMainLight">
								{
									configuration.requestsPolicy
										.maxRequestsPerTimeWindow
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time Window In Secs</Col>
							<Col className="valMainLight">
								{
									configuration.requestsPolicy
										.timeWindowInSecs
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Parallel Client Count</Col>
							<Col className="valMainLight">
								{
									configuration.requestsPolicy
										.parallelClientCount
								}
							</Col>
						</Row>
						<br />
						<br />
					</Container>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Endpoints</b>

						<Row className="rowSpace">
							<Col xs="2">Authorization Code Authorize</Col>
							<Col className="valMainLight">
								<Link>
									{
										configuration.endpoints
											.authorizationCodeAuthorize
									}
								</Link>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Authorization Code Token</Col>
							<Col className="valMainLight">
								<Link>
									{
										configuration.endpoints
											.authorizationCodeToken
									}
								</Link>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Authorization RedirectUri</Col>
							<Col className="valMainLight">
								<Link>
									{' '}
									{
										configuration.endpoints
											.authorizationRedirectUri
									}
								</Link>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">ClientCredentials Token</Col>
							<Col className="valMainLight">
								<Link>
									{' '}
									{
										configuration.endpoints
											.clientCredentialsToken
									}
								</Link>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Vault Consent Host</Col>
							<Col className="valMainLight">
								<Link>
									{' '}
									{configuration.endpoints.vaultConsentHost}
								</Link>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Vault Consent Home Path</Col>
							<Col className="valMainLight">
								<Link>
									{' '}
									{
										configuration.endpoints
											.vaultConsentHomePath
									}
								</Link>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Vault Resource Subscribe Path</Col>
							<Col className="valMainLight">
								<Link>
									{' '}
									{
										configuration.endpoints
											.vaultResourceSubscribePath
									}
								</Link>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Vault Resource Deposit Path</Col>
							<Col className="valMainLight">
								<Link>
									{' '}
									{
										configuration.endpoints
											.vaultResourceDepositPath
									}
								</Link>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Vault Resource Deposit Type</Col>
							<Col className="valMainLight">
								{
									configuration.endpoints
										.vaultResourceDepositType
								}
							</Col>
						</Row>
						<br />
						<br />
					</Container>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Deposit Fields – Cardholder receipt</b>

						<Row className="rowSpace">
							<Col xs="2">titlePattern</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs
										.cardHolder.titlePattern
								}
							</Col>
						</Row>

						<Row className="rowSpace">
							<Col xs="2">authorTagsPattern</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs
										.cardHolder.authorTagsPattern
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">filenamePattern</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs
										.cardHolder.filenamePattern
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">type</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs
										.cardHolder.type
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">validUntil</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs
										.cardHolder.validUntil
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">health</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs
										.cardHolder.health
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">dateFormat</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs
										.cardHolder.dateFormat
								}
							</Col>
						</Row>
						<br />
						<br />
					</Container>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Deposit Fields – Sales receipt</b>

						<Row className="rowSpace">
							<Col xs="2">titlePattern</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs.sales
										.titlePattern
								}
							</Col>
						</Row>

						<Row className="rowSpace">
							<Col xs="2">authorTagsPattern</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs.sales
										.authorTagsPattern
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">filenamePattern</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs.sales
										.filenamePattern
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">type</Col>
							<Col className="valMainLight">
								{configuration.depositFieldConfigs.sales.type}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">validUntil</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs.sales
										.validUntil
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">health</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs.sales
										.health
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">dateFormat</Col>
							<Col className="valMainLight">
								{
									configuration.depositFieldConfigs.sales
										.dateFormat
								}
							</Col>
						</Row>
						<br />
						<br />
					</Container>
					<h4>
						<b>Security Material</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Time updated</Col>
						<Col className="valMainLight">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{securityMaterial.timeUpdated}
							</Moment>
						</Col>
					</Row>

					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Authentication</b>

						<Row className="rowSpace">
							<Col xs="2">App key</Col>
							<Col className="valMainLight">
								{securityMaterial.authentication.appKey}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Client ID</Col>
							<Col className="valMainLight">
								{securityMaterial.authentication.clientId}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Client secret</Col>
							<Col className="valMainLight">
								{securityMaterial.authentication.clientSecret}
							</Col>
						</Row>
						<br />
					</Container>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Client Credentials Token</b>

						<Row className="rowSpace">
							<Col xs="2">Access token</Col>
							<Col className="valMainLight">
								{
									securityMaterial.clientCredentialsToken
										.access_token
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Expires in</Col>
							<Col className="valMainLight">
								{
									securityMaterial.clientCredentialsToken
										.expires_in
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Scope</Col>
							<Col className="valMainLight">
								{
									securityMaterial.clientCredentialsToken
										.scope
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Token type</Col>
							<Col className="valMainLight">
								{
									securityMaterial.clientCredentialsToken
										.token_type
								}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time issued</Col>
							<Col className="valMainLight">
								{' '}
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{
										securityMaterial.clientCredentialsToken
											.timeIssued
									}
								</Moment>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time of expiry</Col>
							<Col className="valMainLight">
								{' '}
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{
										securityMaterial.clientCredentialsToken
											.timeOfExpiry
									}
								</Moment>
							</Col>
						</Row>

						<br />
					</Container>
					<Button
						color="secondary"
						tag={Link}
						to={`/api/rest/vaults/${vaultBasePath}`}
					>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(VaultConfiguration);
