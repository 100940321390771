import React, { Component } from 'react';

import {
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
} from 'reactstrap';

import {
	stringToDateDDMMYYYY,
	dateToStringDDMMYYYY,
	isDateValid,
} from './Utils.js';

class ConsentFlowFilter extends Component {
	constructor(props) {
        super(props);

        const {filterInfo} = this.props;

        /*
        this.state = {
           isLoading: true,
           filterInfo: this.props.filterInfo
        };
        */

        this.state = {
            filterInfo: filterInfo,
            inputState: {   // - (empty), NOK, OK
                dateFrom: filterInfo.dateFrom ? 'OK' : '-',
                dateTo: filterInfo.dateTo ? 'OK' : '-',
                lastDays: filterInfo.lastDays ? 'OK' : '-',
            }
        };

        console.log("ConsentFlowFilter.constructor: show = " + this.props.show);
 
    }

    async componentDidMount() {

        console.log("ConsentFlowFilter.componentDidMount");

     }

    onToggleEnumOutcome = (selected) => {

        console.log("ConsentFlowFilter.onToggleEnumOutcome: selected = " + selected);

        const {filterInfo} = this.state;
        const {flowStatus} = filterInfo;

        flowStatus[selected]['active'] = !flowStatus[selected]['active'];

        this.setState({
            filterInfo: filterInfo
        });
    }
 

    getButtonColor = (selected) => {

        if (selected)
            return {
                backgroundColor: '#f1c1f1',
                color: 'black',
                marginLeft: '6px',
                paddingRight: '6px'
            };

        return {
            backgroundColor: '#f1f1f1',
            color: 'black',
            marginLeft: '6px',
            paddingRight: '6px'
        };
    }
    
    onValidate = () => {
   
        console.log("ConversationFilter.onValidate");
        this.props.onValidate(this.state.filterInfo);

    }
    
    onCancel = () => {
        console.log("ConversationFilter.onCancel");
        this.props.onCancel();
    }

    getColorClass = (inputState) => {
   
        if (inputState) {
            if (inputState === 'OK')
                return 'inputValOk';
            if (inputState === 'NOK')
                return 'inputValErr';
        }

        return 'inputValEmpty';
    }

    onChangeLastDays = (event) => {		

		const target = event.target;
		const valInput = target.value;

		//console.log("ConversationFilter.onChangeLastDays: valInput = " + valInput);

        const {filterInfo, inputState} = this.state;

        let stateNew = '-';

        let valNew = 0;

        if (valInput) {
            valNew = parseInt(valInput);

            if (isNaN(valNew) || valNew <= 0) {
                valNew = 0;
                stateNew = 'NOK';
            }
            else
                stateNew = 'OK';
        }

        let stateCurr = inputState.lastDays;

        if (stateCurr === stateNew && stateCurr !== 'OK')
            return;

        filterInfo.lastDays = valNew;
        inputState.lastDays = stateNew;

        this.setState({
            filterInfo: filterInfo,
            inputState: inputState
        });
    }
    
    onChangeDateFrom = (event) => {	
            
		const {value} = event.target;

        //console.log("ConversationFilter.onChangeDateFrom: value = " + value);

        this.onChangeDateFromTo('dateFrom', value);

    }

    onChangeDateTo = (event) => {	
            
		const {value} = event.target;

        //console.log("ConversationFilter.onChangeDateTo: value = " + value);

        this.onChangeDateFromTo('dateTo', value);
    }
    
    onChangeDateFromTo = (field, valEntered) => {

        const {filterInfo, inputState} = this.state;

        let stateCurr = inputState[field];

        let valNew = null;

        let stateNew = '-';

        if (valEntered) {
            stateNew = 'NOK';
            const dateEntered = stringToDateDDMMYYYY(valEntered);
            const timeEntered = dateEntered ? dateEntered.getTime() : NaN; // dateEntered.getTime() returns NaN if invalid

            if (isNaN(timeEntered)) {
                console.warn("ConversationFilter.onChangeDateFromTo: not a date = " + valEntered);
            }
            else {
                const timeNow = Date.now();
                if (timeEntered > timeNow) {
                    console.warn("ConversationFilter.onChangeDateFromTo: date greater than now= " + valEntered);
                }
                else {
                    valNew = dateToStringDDMMYYYY(dateEntered);
                    stateNew = 'OK';
                }
            }
        }

        if (stateCurr === stateNew && stateCurr !== 'OK')
            return;
        
        console.log("ConversationFilter.onChangeDateFromTo: dateNew = " + valNew);
    
        filterInfo[field] = valNew;
        inputState[field] = stateNew;

        this.setState({
            filterInfo: filterInfo
        });

    }

    render() {

        console.log(`ConversationFilter.render: show = ${this.props.show}`);

        const {filterInfo, inputState} = this.state;
         let flowStatusList = '';

        const {flowStatus} = filterInfo;

        if (this.props.show) {

            let separator = '';

            flowStatusList = flowStatus.map(item => {

                separator = item.last ? '' : ' ';

                return (
                    <div>
                        <Button size="sm" style={this.getButtonColor(item.active)} onClick={() => this.onToggleEnumOutcome(item.index)} 
                        active={item.active}>{item.label}</Button>
                        {separator}
                    </div>
                )
            });
    
      
    
            
        }
        const defaultDateFrom = filterInfo.dateFrom ? filterInfo.dateFrom.replace(/-/g, '/') : '';
        const defaultDateTo = filterInfo.dateTo ? filterInfo.dateTo.replace(/-/g, '/') : '';

        const daysNotEmpty = inputState.lastDays !== '-';
        const dateNotEmpty = inputState.dateFrom !== '-' || inputState.dateTo !== '-';

        const validateDisabled = inputState.lastDays === 'NOK' || inputState.dateFrom === 'NOK' || inputState.dateTo === 'NOK';

        return (
            <div>
                <Modal isOpen={this.props.show} >
                    <ModalHeader>Consent Flows Filter </ModalHeader>

                    <ModalBody>
                        <h6><b>Whole-flow completion</b></h6>
                        <ButtonGroup>
                            {flowStatusList}
                        </ButtonGroup>
                        <br/><br/>

                     
                        <h6><b>TIME STARTED:</b></h6>

                        <div style={{marginBottom: '7px'}}>                        
                            <span className='inputLabel'>Last Days</span>&nbsp;&nbsp;
                            (<span className={this.getColorClass(inputState.lastDays)}>N</span>)
                        </div>
                        <Input
                            type="text"
                            disabled={dateNotEmpty}
                            pattern="[0-9]*"
                            size="3"
                            name="lastDays"
                            id="lastDays"
                            style={{}}
                            defaultValue={filterInfo.lastDays || ''}
                            onChange={this.onChangeLastDays}
                        />
                        <br/>

                        <div style={{marginBottom: '7px'}}>                        
                            <span className='inputLabel'>From Date</span>&nbsp;&nbsp;
                            (<span className={this.getColorClass(inputState.dateFrom)}>DD/MM/YYYY</span>)
                        </div>
                        <Input
                            type="text"
                            disabled={daysNotEmpty}
                            name="dateFrom"
                            id="dateFrom"
                            defaultValue={defaultDateFrom}
                            onChange={this.onChangeDateFrom}
                        />
                        <br/>

                        <div style={{marginBottom: '7px'}}>                        
                            <span className='inputLabel'>To Date</span>&nbsp;&nbsp;
                            (<span className={this.getColorClass(inputState.dateTo)}>DD/MM/YYYY</span>)
                        </div>
                        <Input
                            type="text"
                            disabled={daysNotEmpty}
                            name="dateTo"
                            id="dateTo"
                            defaultValue={defaultDateTo}
                            onChange={this.onChangeDateTo}
                        />
                        <br/>

                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' disabled={validateDisabled} onClick={this.onValidate}>Validate</Button>{' '}
                        <Button color='secondary' onClick={this.onCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
              
            </div>
        );
    }
}

export default ConsentFlowFilter;
