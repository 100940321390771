import React, {useState,useContext, Component} from 'react';
import { v1 as uuidv1 } from 'uuid';
import InputMask from 'react-input-mask';
import { checkResponseJson, checkResponseBody} from './Utils.js';
import authHeader from '../services/auth-header';
import image from '../img/wordline.png';
import card from '../img/card.PNG';
import { Link } from 'react-router-dom';
import * as Const from '../Const';
import {
	Button
} from 'reactstrap';
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class CrmTokenEdit extends Component {
  constructor(props) {
		super(props);
    this.handleChangeCardNumber1 = this.handleChangeCardNumber1.bind(this);
    this.handleChangeCardNumber2 = this.handleChangeCardNumber2.bind(this);
    this.handleChangeCardNumber3 = this.handleChangeCardNumber3.bind(this);
    this.handleChangeCardNumber4 = this.handleChangeCardNumber4.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendCardNumber = this.sendCardNumber.bind(this);
		this.state = {    cardNumber: "", cardNumber1: "",
    cardNumber2: "", cardNumber3: "", cardNumber4: "",
      items: [], isLoading: true};
	}

	async componentDidMount() {

		const {merchantBasePath} = this.props.match.params;

		this.setState({isLoading: true});
   
	}
  
 async sendCardNumber() {
     this.state.cardNumber=this.state.cardNumber1+this.state.cardNumber2+this.state.cardNumber3+this.state.cardNumber4;
     alert(JSON.stringify(this.state.cardNumber));
  }
  handleReset = () => {
    this.setState({  cardNumber: "",
      cardNumber1 : "",
      cardNumber2: "", cardNumber3: "", cardNumber4: ""
    });
  };
  async handleChangeCardNumber1(event) {
  this.setState({
   cardNumber1: event.target.value,
  });
    console.log("card number"+this.state.cardNumber1);
 
  } 
  async handleSubmit(event) {
    this.state.cardNumber=this.state.cardNumber1+this.state.cardNumber2+this.state.cardNumber3+this.state.cardNumber4;
    this.state.cardNumber = this.state.cardNumber.replace('_','');
 
		event.preventDefault();
 
		const {merchantBasePath  } = this.props.match.params;

 
		let submitUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/crmTokens`;

 
		let vaultInfo = {'vaultId': uuidv1()};
    let  cardInfo  = {'clearPan':this.state.cardNumber};
  	let assignCard = {
			'cardInfo': cardInfo,
			'vaultInfo': vaultInfo
		};

		const response = await fetch(submitUrl, {
			method: 'POST',
			headers: Object.assign(
				authHeader(), 
				{
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			),
			body: JSON.stringify(assignCard),
		});

		let drhRsp = checkResponseJson(response);
    console.log("CrmTokenEdit.handleSubmit: json = " + JSON.stringify(drhRsp));

		if (!drhRsp.statusError)  {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp);
        console.log("CrmTokenEdit.handleSubmit: json = " + JSON.stringify(drhRsp));

				const {statusError} = drhRsp;
        console.log("CrmTokenEdit.handleSubmit: json = " + JSON.stringify(jsonBody));

				if (statusError) {
					console.error("CrmTokenEdit.handleSubmit: json = " + JSON.stringify(jsonBody));
					window.alert(`Error: ${statusError.component}.${statusError.error}`);		
				}
			});
		}


		this.props.history.push(`/api/rest/merchants/${merchantBasePath}/crmTokens`);
	}
  async handleChangeCardNumber2(event) {
    this.setState({
     cardNumber2: event.target.value,
  
    });
      console.log("card number"+this.state.cardNumber2); 
   
    } async handleChangeCardNumber3(event) {
      this.setState({
       cardNumber3: event.target.value,
    
      });
        console.log("card number"+this.state.cardNumber3);
     
      } async handleChangeCardNumber4(event) {
        this.setState({
         cardNumber4: event.target.value,
      
        });
          console.log("card number"+this.state.cardNumber4); 
       
        }   
   
	 
	render() {
    const {merchantBasePath} = this.props.match.params;

  return (
    
    <div className='card-form__inner'>
            <img className='profile-image'  id ="left" alt='icon' src={image}/>
            <br/><br/>
      <div className='card-input'>

      Simple, fast and reliable,<br/>
register your card and benefit from the automatic reception<br/>
of your receipts and invoices during your purchases with this bank card<br/><br/>
<br/>
Present only the 16 digits of your bank card

				
<br/>
					<br/><br/>

			 
      <div className='card-form__row'>
                <div className='card-form__col'>
          
           <img className='profile-image'  id ="left" alt='icon' src={card}/>
         </div>          
        
        <div className='card-form__col'>
           
          <InputMask
          id='cardNumber1'
          className='card-input__input'
          autoComplete='off'
          name="cardNumber1"
          value={this.state.cardNumber1}
          onChange={this.handleChangeCardNumber1}
          validations={[required]}
          mask=  '9999'
          alwaysShowMask
         />
          </div>
          <div className='card-form__col'>
           
           <InputMask
           id='cardNumber2'
           className='card-input__input'
           autoComplete='off'
           name="cardNumber2"
           value={this.state.cardNumber2}
           onChange={this.handleChangeCardNumber2}
           validations={[required]}
           mask=  '9999'
           alwaysShowMask
          />
           </div>
           <div className='card-form__col'>
           
           <InputMask
           id='cardNumber3'
           className='card-input__input'
           autoComplete='off'
           name="cardNumber3"
           value={this.state.cardNumber3}
           onChange={this.handleChangeCardNumber3}
           validations={[required]}
           mask=  '9999'
           alwaysShowMask
          />
           </div>
           <div className='card-form__col'>
           
           <InputMask
           id='cardNumber4'
           className='card-input__input'
           autoComplete='off'
           name="cardNumber4"
           value={this.state.cardNumber4}
           onChange={this.handleChangeCardNumber4}
           validations={[required]}
           mask=  '9999'
           alwaysShowMask
          />
           </div>
        <div className='card-form__col'>
           
            <Button color="primary"  disabled={!this.state.cardNumber1 && !this.state.cardNumber2&& !this.state.cardNumber3 && !this.state.cardNumber4} 
						 onClick={this.handleSubmit}
							>	   Submit</Button>

         </div>
        <div className='card-form__col'>
        <Button
								color="secondary"
								tag={Link}
								to={`/api/rest/merchants/${merchantBasePath}/crmTokens`}
							>
								Cancel
							</Button>
         </div>
      </div>
    </div>
    </div>

  );
}}

export default CrmTokenEdit;
