import React, { Component } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Const from '../Const';
import StatusError from './StatusError';
import {
	formatEnum,
	getLevelClass,
	checkResponseJson,
	checkResponseBody,
	loadPaginationConfig,
	queryInfoDefault,
	searchParamsToQueryInfo,
	queryInfoToSearchParams,
	queryInfoRowCountStr,
} from './Utils.js';
import authHeader from '../services/auth-header';
import Pagination from 'react-js-pagination';
import Moment from 'react-moment';
import ReceiptFilter from './ReceiptFilter';

class DepositReservationList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			modal: false,
			queryInfo: queryInfoDefault(),
		
		};

		this.loadList = this.loadList.bind(this);
		this.onListRefresh = this.onListRefresh.bind(this);

		
	}

	async componentDidMount() {
		console.log('DepositReservationList.componentDidMount');

		const queryInfo = searchParamsToQueryInfo(
			this.props.browserSearchParams,
		);

		this.loadList(queryInfo);
	}

	onListRefresh() {
		this.loadList(this.state.queryInfo);
	}



	async loadList(queryInfo) {
		console.log('DepositReservationList.loadList');

		this.setState({
			isLoading: true,
		});


		const { vaultBasePath } = this.props;

		let getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}/depositReservations`;

		console.log('DepositReservationList.loadList: getUrl = ' + getUrl);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['reservations']);

				if (drhRsp.statusError) {
					console.error(
						'DepositReservationList.loadList: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

	
		this.setState({
			items: drhRsp.body ? drhRsp.body.reservations : null,
			queryInfo: queryInfo,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	render() {
		const {
			items,
			httpInfo,
			statusError,
			isLoading,
		} = this.state;
		const { vaultBasePath } = this.props;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		const itemList = items.map((item) => {
			const viewLink = `/api/rest/vaults/${vaultBasePath}/receipts/${item.receiptId}`;

			return (
				<tr key={item.hubInstanceId}>
					<td title={item.hubInstanceId} className="hexIdGray">
						{item.hubInstanceId}
					</td>
					<td  className="valMainText">
						<b>{item.transactionId}</b>
					</td>
					<td className="valMain fitwidth">
						{item.receiptId}
					</td>
					<td className="state">
						<Moment format="YYYY-MM-DD HH:mm:ss">
							{item.timeCreated}
						</Moment>
					</td>
					<td
						className={getLevelClass(item.reservationType)}
						style={{ width: 40, textAlign: 'left' }}
						title={item.reservationType}
					>
						{formatEnum(item.reservationType)}
					</td>
 
					<td>
						<Button
							size="sm"
							color="secondary"
							tag={Link}
							to={viewLink}
						>
							View
						</Button>
					</td>
				</tr>
			);
		});

 		return (
			<div>
				<Container>
					<Button
						size="sm"
						color="secondary"
						title="Refresh"
						onClick={() => this.onListRefresh()}
					>
						{'\u21BB'}
					</Button>
					&nbsp;&nbsp;
				 
					<br />
					<br />
				 
					<Table className="mt-4" width="1300px">
						<thead>
							<tr>
								<th width="60px">Hub Instance</th>
								<th width="110px">Transaction Short ID</th>
								<th width="110px">Receipt ID</th>
								<th width="110px">Time created</th>
								<th width="110px">Type</th>
								<th width="120px">Actions</th>
							</tr>
						</thead>
						<tbody>{itemList}</tbody>
					</Table>
					<br />
				</Container>
			</div>
		);
	}
}

export default DepositReservationList;
