

const PAGINATION_PARAMS = ['pageActive', 'pageSize'];

const FILTERING_PARAMS_MULTI = ['machineType','runningState','cardholderToVault', 'salesToVault','flowStatus','receiptType','receiptToVault','firstBy','assignment'];     // 0..N choices

const FILTERING_PARAMS_SINGLE = ['lastDays', 'dateFrom', 'dateTo','lastDays2', 'dateFrom2', 'dateTo2', 'tokenValue', 'alias','tnxCountMin','tnxCountMax']; // 0..1 choice

const SORTING_PARAMS = []; // for future use

function dumpObject(tag, name, obj) {

	if (obj === null) {
		console.log(`${tag}: OBJECT '${name}': null`); // note: typeof null is 'object'
		return;
	}

	const objType = typeof obj;

	if (objType === 'boolean') {
		console.log(`${tag}: BOOLEAN \n\t'${name}': ${obj}`);
		return;
	}

	if (objType === 'string') {
		console.log(`${tag}: STRING \n\t'${name}': ${obj}`);
		return;
	}

	if (objType !== 'object') {
		console.log(`${tag}: TODO DUMP '${name}': ${objType}`);
		return;
	}

	console.log(`${tag}: OBJECT\n\t'${name}':`);

	for(var prop in obj) {
		
		if (prop instanceof Function)
			continue;
					
		const propType = typeof prop;

		if (prop instanceof Function || propType === 'function')
			continue;

		const propVal = obj[prop];

		//if (propType === 'string' && propVal && propVal.startsWith('function '))
		//	continue;

		//console.log(`\t\t? '${prop}': ${propType}`);

		if (propType !== 'function')
			console.log(`\t\t> '${prop}': ${propVal}`);
	}

}

function formatEnum(enu) {

	// weak UI patching

	if (!enu)
		return undefined;
	
	return enu.replace(/_/g, ' ').replace(/ AND /g, ' & ').replace(/-/g, ' ').replace('-', ' ')
    
    ;
}

function formatReverseEnum(enu) {

	// weak UI patching

	if (!enu)
		return undefined;
	
	return enu.replace(' ', '-')
    
    ;
}

function formatEnumNonBreaking(enu) {

	if (!enu)
		return undefined;
	
	return enu.replace(/-/g, '\u2011');
}

 

function formatIfInt(strVal, isInt) {

	if (isInt) {
		const intDefault = parseInt(strVal); 
		if (intDefault)
			return intDefault.toLocaleString();
	}
	return strVal;
}

function checkResponseJson(response) {

	let drhRsp = {
		httpInfo: {
			ok: response.ok,
			status: response.status,
			statusText: response.statusText,
			url: response.url,
			contentType: response.headers.get('content-type')
		},
		item: null
	};

	let error = undefined;
	let component = 'http';

	//console.log("parsedDRHResponse: response.ok = " + response.ok);

	if (response.ok) {
		const {contentType} = drhRsp.httpInfo;

		console.log("parseDRHResponse: contentType = " + contentType);

		if (contentType && contentType.includes('application/json')) {			
			error = undefined;	
			component = undefined;
		}
		else {
			component = 'contentType';
			error = 'unexpected';
		}
	}

	if (error || component) {
		drhRsp.statusError = {	// empty if success
			error: error,
			component: component
		}
	}

	return drhRsp;
}

function formatLocalDateTime(sDateUtc, format = 'DHSm') {

	/*
		D: Date           DD/MM/YYY
		H: Time                     HH:mm
		S: Seconds                       :SS
		m: Milliseconds                     .sss
	*/	 

	if (!sDateUtc)
		return '';
		          
	// expected: 2020-09-29 01:01:51.305 or 2020-09-29 01:01:51
	//           01234567890123456789012    0123456789012345678
	const len = sDateUtc.length;

	if (len !== 19 && len !== 23 && len !== 28) {
		console.error("formatLocalDate: " + sDateUtc);
		return '?';
	}

	const dateUtc = new Date(sDateUtc.substring(0, 10) + 'T' + sDateUtc.substring(11, len) + '+00:00');

	let localDateTime = '';
	let sep = '';

	if (format.includes('D')) {
		localDateTime = dateUtc.toLocaleDateString('fr-FR');
		sep = ' ';
	}

	if (format.includes('H')) {
		localDateTime += sep;
		const HMS = dateUtc.toLocaleTimeString('fr-FR');
		if (format.includes('S')) {
			localDateTime += HMS;

			if (format.includes('m')) {			
				const millis = "." + dateUtc.getMilliseconds() + "00";
				localDateTime += millis.substring(0, 4);			
			}
		}
		else {
			localDateTime += HMS.substring(0, 5);
		}	
	}

	return localDateTime;
}

function checkResponseBody(jsonBody, drhRsp, itemNames) {

	// itemNames: string list of JSON body properties that must be present (not including 'status')

	let error = '?';
	let component = '?';

	let status = jsonBody.status;

	let setBody = false;

	if (status) {
		setBody = true;

		if (status.success) {
			error = undefined;	
			component = undefined;
		}
		else {
			if (status.error)
				error = status.error;
			if (status.component)	
				component = status.component;
		}

		if (!error && !component && itemNames) {

			for (const itemName of itemNames) {

				//console.log("checkResponseBody: itemName = " + itemName);

				if (typeof jsonBody[itemName] === 'undefined') {
					error = 'missing';
					component = itemName;
					setBody = false;
					break;
				}
			}
		}
	}
	else {
		error = 'notFound';
		component = 'status';
	}
	if (setBody)
    drhRsp.body = jsonBody;

	if (error || component) {
		drhRsp.statusError = {
			error: error,
			component: component
		}
	}
	else {
		delete drhRsp.httpInfo;
	}
}

function decodePermissions(crudPermissions) {
	
	let permissions = {
		add: false,
		view: false,
		edit: false,
		delete: false
	}

	if (crudPermissions && crudPermissions.length === 4) {
		if (crudPermissions.charAt(0) === 'C') {
			permissions.add = true;
		}
		if (crudPermissions.charAt(1) === 'R') {
			permissions.view = true;
		}
		if (crudPermissions.charAt(2) === 'U') {
			permissions.edit = true;
		}
		if (crudPermissions.charAt(3) === 'D') {
			permissions.delete = true;
		}
	}
	return permissions;	

}

function getLevelClass(level) {

	if (level === 'success' || level === 'AVAILABLE' || level === 'RUNNING'  || level === 'ASSIGNED'  || level === 'LIVE' || level === 'DONE SUCCESS' || level === 'DONE-SUCCESS')
		return 'valMainOk';
	
	if (level === 'warning' || level === 'IN PROGRESS' || level === 'IN-PROGRESS')
		return 'valMainWarn';

	if (level === 'error' || level === 'ERROR' || level === 'DONE-ERROR')
		return 'valMainErr';

        if (level === 'NOT-PLANNED')
		return 'valMainhex';
	if (level === 'undefined')
		return 'valMainUndef';
        if (level === '')
		return 'valMainUndef';

	return '';
}

function getMonthLengthArray(year) {

    let monthLengths = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if ((year % 400) === 0 || ((year % 100) !== 0 && (year % 4) === 0))
        monthLengths[1] = 29;

    return monthLengths;
}

function stringToDateDDMMYYYY(dateString, separator = '/') // DD-MM-YYYY or DD/MM/YYYY
{
    if (!dateString) {
        console.warn('stringToDateDDMMYYYY: empty');
        return null;
    }        

    if (separator && separator === '-') {
        if(!/^\d{2}-\d{2}-\d{4}$/.test(dateString))
            return null;
            
        dateString = dateString.replace(/-/g, '/');
    }

    const match = dateString.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);

    if (!match) {
        //console.warn('stringToDateDDMMYYYY: not matching ' + dateString);
        return null;
    }
    
    //console.log('stringToDateDDMMYYYY: match = ' + match);

    let day = parseInt(match[1], 10);
    let month = parseInt(match[2], 10);
    let year = parseInt(match[3], 10);

    if (year < 1000 || year > 3000 || month < 1 || month > 12 || day < 1) {
        //console.warn('stringToDateDDMMYYYY: out of range');
        return null;
    } 

    // control on max days per month has to be done explicitly, because
    // the Date constructor accepts '2020/01/32' and stores it as '2020/02/01'

    const monthLengths = getMonthLengthArray(year);

    if (day > monthLengths[month - 1]) {
        //console.warn('stringToDateDDMMYYYY: day out of range');
        return null;
    }

    //console.log(`stringToDateDDMMYYYY: DD:${day}, MM:${month}, YYYY:${year}`);

    const dateOut = new Date(year, month - 1, day);

    return dateOut;
}

function isDateValid(dateString, separator = '/') {

    const date = stringToDateDDMMYYYY(dateString, separator);

    return date && !isNaN(date.getTime());
}

function dateToStringDDMMYYYY(date, separator = '/') {
         
    if (!date || isNaN(date.getTime()))
        return null;

    const day = date.getDate();
    const month = date.getMonth() + 1;

    return (day < 10 ? '0' : '') + day + separator + 
           (month < 10 ? '0' : '') + month + separator +
           date.getFullYear();
}

function filterInfoDefault() {

    return {
       
        salesToVault: [
                { index: 0, label: 'NOT PLANNED', param: 'NOT PLANNED', active: false },
                { index: 1, label: 'NOT PROVIDED', param: 'NOT PROVIDED', active: false },
                { index: 2, label: 'PLANNED',   param: 'PLANNED',   active: false },
                { index: 3, label: 'GHOST',   param: 'GHOST',   active: false },
                { index: 4, label: 'IN PROGRESS',   param: 'IN PROGRESS',   active: false },
                { index: 5, label: 'AVAILABLE',     param: 'AVAILABLE',     active: false, last: true },
                { index: 6, label: 'ERROR',   param: 'ERROR',   active: false }
    
         ],
         cardholderToVault: [
            { index: 0, label: 'NOT PLANNED', param: 'NOT PLANNED', active: false },
            { index: 1, label: 'NOT PROVIDED', param: 'NOT PROVIDED', active: false },
            { index: 2, label: 'PLANNED',   param: 'PLANNED',   active: false },
            { index: 3, label: 'GHOST',   param: 'GHOST',   active: false },
            { index: 4, label: 'IN PROGRESS',   param: 'IN PROGRESS',   active: false },
            { index: 5, label: 'AVAILABLE',     param: 'AVAILABLE',     active: false, last: true },
            { index: 6, label: 'ERROR',   param: 'ERROR',   active: false }

        ],
        flowStatus: [
              
                { index: 0, label: 'IN PROGRESS',   param: 'IN PROGRESS',   active: false },
                { index: 1, label: 'DONE ERROR',     param: 'DONE ERROR',     active: false },
                { index: 2, label: 'DONE SUCCESS',   param: 'DONE SUCCESS',   active: false , last: true },
        ], 
        receiptType: [
            { index: 0, label: 'MERCHANT',    param: 'MERCHANT',    active: false },
            { index: 1, label: 'CARDHOLDER',    param: 'CARDHOLDER',    active: false },
            { index: 2, label: 'SALES', param: 'SALES', active: false },
        ],
        receiptToVault: [
            { index: 0, label: 'NOT PLANNED', param: 'NOT PLANNED', active: false },
            { index: 1, label: 'NOT PROVIDED', param: 'NOT PROVIDED', active: false },
            { index: 2, label: 'PLANNED',   param: 'PLANNED',   active: false },
            { index: 3, label: 'GHOST',   param: 'GHOST',   active: false },
            { index: 4, label: 'IN PROGRESS',   param: 'IN PROGRESS',   active: false },
            { index: 5, label: 'AVAILABLE',     param: 'AVAILABLE',     active: false, last: true },
            { index: 6, label: 'ERROR',   param: 'ERROR',   active: false }

        ],
        firstBy : [
            { index: 0, label: 'TERMINAL',    param: 'TERMINAL',    active: false },
            { index: 1, label: 'WEB PAGE', param: 'WEB PAGE', active: false },
        ], 
        assignment  : [
            { index: 0, label: 'GHOST', param: 'GHOST', active: false },
            { index: 1, label: 'ASSIGNED', param: 'ASSIGNED', active: false },
            { index: 2, label: 'REVOKED',   param: 'REVOKED',   active: false },
            { index: 3, label: 'EXPIRED',   param: 'EXPIRED',   active: false },
         
    ],  machineType: [
        { index: 0, label: 'AWS EC2',    param: 'AWS EC2',    active: false },
        { index: 1, label: 'COMPUTER',    param: 'COMPUTER',    active: false },
     ], runningState: [
        { index: 0, label: 'RUNNING',    param: 'RUNNING',    active: false },
        { index: 1, label: 'OFFLINE',    param: 'OFFLINE',    active: false },
     ],
        state: [
            { index: 0, label: 'STARTED',    param: 'STARTED',    active: false },
            { index: 1, label: 'FINALIZING', param: 'FINALIZING', active: false },
            { index: 2, label: 'FINALIZED',  param: 'FINALIZED',  active: false },
            { index: 3, label: 'CLOSED',     param: 'CLOSED',     active: false,  last: true },
        ],
       
       
        dateFrom: null,
        dateTo: null,
        lastDays: 0,
        dateFrom2: null,
        dateTo2: null,
        lastDays2: 0,
        tnxCountMin: 0,
        tnxCountMax: 0,
        tokenValue  : null,
        alias: null
    };
}

function sortInfoDefault() {
    return {};
}

function queryInfoDefault() {

    return {
        paginationInfo: {       // Provided by    
            pageActive: 1,      // USER & RESPONSE
            pageCount: 1,       //        RESPONSE
            pageSize: 20,       // USER & RESPONSE
            rowCountInTotal: 0, //        RESPONSE
            rowCountInPage: 0   //        RESPONSE
        },
        filterInfo: filterInfoDefault(),    // Provided by USER
        sortInfo: sortInfoDefault()    // Provided by USER
    }
}

function queryInfoRowCountStr(queryInfo) {

    if (queryInfo && queryInfo.paginationInfo) {

        const rowCountInTotal = queryInfo.paginationInfo.rowCountInTotal;

        if (typeof rowCountInTotal === 'number') {
            if (rowCountInTotal < 2) 
                return rowCountInTotal + ' row.';                
            else
                return rowCountInTotal + ' rows.';                
        }
    }

    return '';
}

function searchParamsToQueryInfo(urlSearchParams) {

    // searchParams type: URLSearchParams
    // see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams

    let queryInfo = queryInfoDefault();

    const filterInfo = queryInfo.filterInfo;

    if (urlSearchParams) {

        for (const [key, value] of urlSearchParams.entries()) {

            console.log(`Utils.searchParamsToQueryInfo: key: ${key}, value: ${value}`);

            if (value) {
                if (FILTERING_PARAMS_SINGLE.includes(key)) {
                    switch(key) {
                        case 'lastDays':
                            const lastDaysInt = parseInt(value);

                            if (!isNaN(lastDaysInt) && lastDaysInt > 0) {
                                filterInfo.lastDays = lastDaysInt;
                            }
                            break;
                        case 'dateFrom':
                            if (isDateValid(value, '-'))
                                filterInfo.dateFrom = value;
                            break;
                        case 'dateTo':
                            if (isDateValid(value, '-'))
                                filterInfo.dateTo = value;
                            break;
                            case 'lastDays2':
                                const lastDaysInt2 = parseInt(value);
    
                                if (!isNaN(lastDaysInt2) && lastDaysInt2 > 0) {
                                    filterInfo.lastDays2 = lastDaysInt2;
                                }
                                break;
                            case 'dateFrom2':
                                if (isDateValid(value, '-'))
                                    filterInfo.dateFrom2 = value;
                                break;
                            case 'dateTo2':
                                if (isDateValid(value, '-'))
                                    filterInfo.dateTo2 = value;
                                break;
                        case 'tokenValue':
                            filterInfo.tokenValue = value;
                            break;
                        case 'alias':
                            filterInfo.alias = value;
                            break;
                            case 'tnxCountMin':
                                 const tnxCountMin = parseInt(value);
    
                                if (!isNaN(tnxCountMin) && tnxCountMin > 0) {
                                    filterInfo.tnxCountMin = tnxCountMin;
                                }
                                break;
                                 
                                case 'tnxCountMax':
                                 const tnxCountMax = parseInt(value);
    
                                if (!isNaN(tnxCountMax) && tnxCountMax > 0) {
                                    filterInfo.tnxCountMax = tnxCountMax;
                                }
                                break;
                        }
                }
                else
                if (FILTERING_PARAMS_MULTI.includes(key)) {

                    /*
                        Examples:
                        outcome     WARNING,SUCCESS
                        outcome     ERROR
                        state       STARTED
                    */
                    let enumObjArray = filterInfo[key];

                    if (Array.isArray(enumObjArray)) {

                        const valueArray = value.split(",");

                        for (const enumObj of enumObjArray) {

                            for (const valueEnum of valueArray) {

                                if (valueEnum === enumObj.param) {
                                    enumObj.active = true;
                                    console.log(`Utils.searchParamsToQueryInfo: ACTIVE enum: ${key}, value: ${valueEnum}`);
                                }
                            }
                        }
                    }
                }
                else
                if (PAGINATION_PARAMS.includes(key)) {
                    queryInfo.paginationInfo[key] = value;
                }
                else
                if (SORTING_PARAMS.includes(key)) {
                    queryInfo.sortInfo[key] = value;
                }
            }
        }
        if (filterInfo.lastDays) {
            filterInfo.dateFrom = null;
            filterInfo.dateTo = null;
        }
        if (filterInfo.lastDays2) {
            filterInfo.dateFrom2 = null;
            filterInfo.dateTo2 = null;
        }
    }
    return queryInfo;
}

function queryInfoToSearchParams(queryInfo) {
    
    let urlSearchParams = new URLSearchParams();

    let hasParam = false;

    if (queryInfo) {

        const {paginationInfo, filterInfo} = queryInfo;

        if (paginationInfo) {

            for (const key in paginationInfo) {

                if (PAGINATION_PARAMS.includes(key)) {
                    const value = paginationInfo[key];
                    if (value) {
                        urlSearchParams.append(key, value);
                        hasParam = true;
                    }
                }
            }
        }
        if (filterInfo) {

            for (const key in filterInfo) {

                const prop = filterInfo[key];
                if (prop) {

                    let finalValue = '';
                    let activeCount = 0;
                    
                    if (FILTERING_PARAMS_MULTI.includes(key) && Array.isArray(prop)) {

                        finalValue = '';

                        for (const enumObj of prop) {

                            if (enumObj.active) {
                                if (activeCount > 0) {
                                    finalValue += ',';                                    
                                }
                                finalValue += formatReverseEnum(enumObj.param); 
                                activeCount++;
                                console.log(`Utils.queryInfoToSearchParams: ACTIVE enum: ${key}, value: ${enumObj.label}`);
                            }
                        }
                        if (activeCount === prop.length)
                            // everything selected
                            activeCount = 0;
                    }
                    else
                    if (FILTERING_PARAMS_SINGLE.includes(key))
                    {
                        console.log(`Utils.queryInfoToSearchParams: SET key: ${key}, value: ${prop}`);
                        if (key.startsWith('date'))
                            finalValue = prop.replace(/\//g, '-');
                        else
                            finalValue = prop;
                        activeCount = 1;
                    }
                    if (activeCount > 0) {
                        urlSearchParams.append(key, finalValue);
                        hasParam = true;
                    }
                }
            }
        }
    }

    return hasParam ? urlSearchParams : null;
}

function loadPaginationConfig() {

    // https://unicode-search.net/unicode-namesearch.pl?term=BRACKET

    return {
        activePage: 1,
        itemsCountPerPage: 20,
        totalItemsCount: 0,
        pageRangeDisplayed: 10,            
        itemClass: 'page-item',
        linkClass: 'page-link',
        firstPageText: '\u2770',
        prevPageText: 'Prev', //'\u276C',
        nextPageText: 'Next', //'\u276D',
        lastPageText: '\u2771'
    };
}

export { 
	checkResponseJson, 
	checkResponseBody, 
	dumpObject, 
    formatEnum,
    formatEnumNonBreaking,
	formatIfInt,
	decodePermissions,
	formatLocalDateTime,
    getLevelClass,
    loadPaginationConfig,
    queryInfoDefault,
    queryInfoRowCountStr,
    searchParamsToQueryInfo,
    queryInfoToSearchParams,
    isDateValid,
    stringToDateDDMMYYYY,
    dateToStringDDMMYYYY
 };

