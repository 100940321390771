import axios from "axios";
import authHeader from "./auth-header";

import * as Const from '../Const';

const API_URL = `${Const.DRH_ENDPOINT_BASE}/api/test`;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "/all");
  }

  getUserBoard() {
    return axios.get(API_URL + "/user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "/moderator", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "/admin", { headers: authHeader() });
  }
}

export default new UserService();
