import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Const from '../Const';
 
 
import XMLViewer from 'react-xml-viewer'

class XMLDocs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}
	async componentDidMount() {
		this.setState({ isLoading: true });


 
		const {
			merchantBasePath,
			xmlDocId,
		} = this.props;
 		const getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/xmlDocs/${xmlDocId}`;
		 let responsexml = null;

		console.log(
			'XMLDocs.componentDidMount: getUrl = ' + getUrl,
		);
		const response = await fetch(getUrl)
 		responsexml = await response.text();

		this.setState({
			item:responsexml,
 		});
	}

	render() {
		const {
			item,
 		} = this.state;
	 
		

		return (
			<div>
     <div div className="white-space-pre-line" style={{border: '1px solid rgba(0, 0, 0, 1)'}} >

				<Container fluid style={{ paddingLeft: 20 }}>
				<XMLViewer xml= {item} 
				 />

				</Container>
			</div>
			</div>
		);
	}
}

export default XMLDocs;
