import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col, Table } from 'reactstrap';
import * as Const from '../Const';
import Moment from 'react-moment';

import StatusError from './StatusError';
import {
	formatEnum,
	getLevelClass,
	checkResponseJson,
	checkResponseBody,
} from './Utils.js';
import authHeader from '../services/auth-header';

class TransactionDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
		this.goBack = this.goBack.bind(this);
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Transaction';

		const {
			merchantBasePath,
			transactionId,
		} = this.props.match.params;
		const queryParams = new URLSearchParams(
			this.props.location.search,
		);

		let crmTokenId = null;

		if (queryParams) {
			crmTokenId = queryParams.get('crmTokenId');
		}
		const getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/transactions/${transactionId}`;
		console.log(
			'TransactionDetail.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['status']);

				if (drhRsp.statusError) {
					console.error(
						'TransactionDetail.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}
		let backLink = '/';

		if (crmTokenId) {
			// TODO: make it possible to go back to 'View CRM TOKEN'
			backLink = `/api/rest/merchants/${merchantBasePath}/crmTokens/${crmTokenId}`;
		}
		// TODO: make it possible to go back to 'View Transaction'
		else
			backLink = `/api/rest/merchants/${merchantBasePath}/transactions`;

		this.setState({
			item: drhRsp.body,
			replayedFrom: drhRsp.body ? drhRsp.body.replayedFrom : null,
			backLink: backLink,
			crmTokenId: crmTokenId,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	goBack() {
		console.log('TransactionDetail.goBack');
		this.props.history.goBack();
	}

	render() {
		const {
			item,
			backLink,
			crmTokenId,
			httpInfo,
			statusError,
			isLoading,
		} = this.state;
		const {
			merchantBasePath,
			transactionId,
		} = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>{'View Transaction'}</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}
		const hashubDocHandling  =
		item.hubDocHandling  &&
		item.hubDocHandling  !== 'undefined';
		const transactionInfo = item.transactionInfo;
		const crmTokenInfo = item.crmTokenInfo;
		const posToVaultInfo = item.posToVaultInfo;
		const receiptProcessingStatus = item.receiptProcessingStatus;
		const itemList = item.receiptProcessingStatus.availability;
		const vaultBasePath = posToVaultInfo.vaultBasePath;
		const crmLink = `/api/rest/merchants/${merchantBasePath}/crmTokens/${crmTokenInfo.crmTokenId}?transactionId=${transactionId}`;
		return (
			<div>
				<Container fluid style={{ paddingLeft: 40 }}>
					{title}
					<br />
					<h4>
						<b>Transaction Info</b>
					</h4>

					<Row className="rowSpace">
						<Col xs="2">Transaction ID</Col>
						<Col className="hexIdGray">
							<b>{transactionInfo.transactionId}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Short ID</Col>
						<Col className="valMainHex">
							{transactionInfo.shortId}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time reported</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{transactionInfo.timeReported}
							</Moment>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time received</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{transactionInfo.timeReceived}
							</Moment>
						</Col>
					</Row>

					<Row className="rowSpace">
						<Col xs="2">Amount</Col>
						<Col className="hexId">
							<b>{transactionInfo.amount}</b>
						</Col>
					</Row>

					<Row className="rowSpace">
						<Col xs="2">Currency</Col>
						<Col>
							<b>{transactionInfo.currency}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Payment system</Col>
						<Col>
							<b>{transactionInfo.paymentSystem}</b>
						</Col>
					</Row>

					<br />

					<br />

					{hashubDocHandling && (
						<>
							<h4>
								<b>Doc Handling (by Hubs)</b>
							</h4>
							{item.hubDocHandling.createdBy && (<>	<Row className="rowSpace">
								<Col xs="2">Created  by</Col>
								<Col className="hexIdGray">
									{item.hubDocHandling.createdBy}
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.createdAt && (<>
							<Row className="rowSpace">
								<Col xs="2">Created  at</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{item.hubDocHandling.createdAt}
									</Moment>
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.updatedBy && (<>
							<Row className="rowSpace">
								<Col xs="2">Updated by</Col>
								<Col className="hexIdGray">
									{item.hubDocHandling.updatedBy}
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.updatedAt && (<>
							<Row className="rowSpace">
								<Col xs="2">Updated at</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{item.hubDocHandling.updatedAt}
									</Moment>
								</Col>
							</Row>
							</>)}
							<br />
							<br />
						</>
					)}
					 
					<br />
					<h4>
						<b>POS to Vault Info</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Merchant name</Col>
						<Col className="valMain">
							{posToVaultInfo.merchantName}
						</Col>
					</Row>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Receipt Session</b>
						<Row className="rowSpace">
							<Col xs="2">Pos ID</Col>
							<Col className="hexId">
								{posToVaultInfo.receiptSession.posId}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Connection ID</Col>
							<Col className="hexId">
								{posToVaultInfo.receiptSession.connectionId}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Receipt Session ID</Col>
							<Col className="hexId">
								{
									posToVaultInfo.receiptSession
										.receiptSessionId
								}
							</Col>
						</Row>
					</Container>

					<br />
					<Row className="rowSpace">
						<Col xs="2">Vault Name</Col>
						<Col className="valMain">
							{posToVaultInfo.vaultName}
						</Col>
					</Row>

					<br />

					<br />
					<h4>
						<b>CRM Token Info</b>
					</h4>

					<Row className="rowSpace">
						<Col xs="2">CRM Token ID</Col>
						<Col className="hexId">
							{crmTokenInfo.crmTokenId}
							&nbsp; &nbsp;
							{!crmTokenId && (
								<Button
									color="secondary"
									tag={Link}
									target="_blank"
									size="sm"
									to={crmLink}
								>
									{'\u3009'}
								</Button>
							)}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">CRM Token Value</Col>
						<Col className="hexId">
							<b>{crmTokenInfo.tokenValue}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Was existing</Col>
						<Col className="valMain">
							{String(crmTokenInfo.wasExisting)}
						</Col>
					</Row>

					<br />
					<h4>
						<b>Receipt Processing Status</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Completed</Col>
						<Col className="valMain">
							{String(receiptProcessingStatus.completed)}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time updated</Col>
						<Col>
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{receiptProcessingStatus.timeUpdated}
							</Moment>{' '}
						</Col>
					</Row>

					<br />
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Availability</b>
						<div className="container">
							<Table className="mt-4" width="1270px">
								<thead>
									<tr>
										<th width="60px">ID</th>
										<th width="60px">Receipt</th>
										<th width="110px">In Hub</th>
										<th width="110px">To Merchant</th>
										<th width="110px">To Vault</th>
										<th width="120px">Actions</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td className="hexIdGray">
											{itemList.salesReceipt.receiptId &&
												itemList.salesReceipt.receiptId.substring(
													0,
													4,
												) + '\u2026'}
										</td>
										<td>{'Sales'}</td>
										<td
											className={getLevelClass(
												itemList.salesReceipt.inHub,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
										>
											{itemList.salesReceipt.inHub}
										</td>
										<td
											className={getLevelClass(
												itemList.salesReceipt.toMerchant,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
											title={
												itemList.salesReceipt.toMerchant
											}
										>
											{formatEnum(
												itemList.salesReceipt.toMerchant,
											)}
										</td>
										<td
											className={getLevelClass(
												itemList.salesReceipt.toVault,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
											title={itemList.salesReceipt.toVault}
										>
											{formatEnum(
												itemList.salesReceipt.toVault,
											)}
										</td>
										<td>
											<Button
												disabled={
													!itemList.salesReceipt
														.receiptId
												}
												size="sm"
												color="secondary"
												tag={Link}
												to={`/api/rest/vaults/${vaultBasePath}/receipts/${itemList.salesReceipt.receiptId}?transactionId=${transactionId}`}
											>
												View
											</Button>
										</td>
									</tr>
									<tr>
										<td className="hexIdGray">
											{itemList.cardholderReceipt
												.receiptId &&
												itemList.cardholderReceipt.receiptId.substring(
													0,
													4,
												) + '\u2026'}
										</td>
										<td>{'Cardholder'}</td>
										<td
											className={getLevelClass(
												itemList.cardholderReceipt.inHub,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
											title={
												itemList.cardholderReceipt.inHub
											}
										>
											{itemList.cardholderReceipt.inHub}
										</td>
										<td
											className={getLevelClass(
												itemList.cardholderReceipt
													.toMerchant,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
											title={
												itemList.cardholderReceipt
													.toMerchant
											}
										>
											{formatEnum(
												itemList.cardholderReceipt
													.toMerchant,
											)}
										</td>
										<td
											className={getLevelClass(
												itemList.cardholderReceipt
													.toVault,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
											title={
												itemList.cardholderReceipt.toVault
											}
										>
											{formatEnum(
												itemList.cardholderReceipt
													.toVault,
											)}
										</td>
										<td>
											<Button
												disabled={
													!itemList.cardholderReceipt
														.receiptId
												}
												size="sm"
												color="secondary"
												tag={Link}
												to={`/api/rest/vaults/${vaultBasePath}/receipts/${itemList.cardholderReceipt.receiptId}?transactionId=${transactionId}`}
											>
												View
											</Button>
										</td>
									</tr>
									<tr>
										<td className="hexIdGray">
											{itemList.merchantReceipt
												.receiptId &&
												itemList.merchantReceipt.receiptId.substring(
													0,
													4,
												) + '\u2026'}
										</td>
										<td>{'Merchant'}</td>
										<td
											className={getLevelClass(
												itemList.merchantReceipt.inHub,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
											title={
												itemList.merchantReceipt.inHub
											}
										>
											{itemList.merchantReceipt.inHub}
										</td>
										<td
											className={getLevelClass(
												itemList.merchantReceipt
													.toMerchant,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
											title={
												itemList.merchantReceipt
													.toMerchant
											}
										>
											<b>
												{formatEnum(
													itemList.merchantReceipt
														.toMerchant,
												)}
											</b>
										</td>
										<td
											className={getLevelClass(
												itemList.merchantReceipt.toVault,
											)}
											style={{
												width: 40,
												textAlign: 'left',
											}}
											title={
												itemList.merchantReceipt.toVault
											}
										>
											{formatEnum(
												itemList.merchantReceipt.toVault,
											)}
										</td>
										<td>
											<Button
												size="sm"
												disabled={
													!itemList.merchantReceipt
														.receiptId
												}
												color="secondary"
												tag={Link}
												to={`/api/rest/vaults/${vaultBasePath}/receipts/${itemList.merchantReceipt.receiptId}?transactionId=${transactionId}`}
											>
												View
											</Button>
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</Container>
					<br />
					<Button color="secondary" tag={Link} to={backLink}>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(TransactionDetail);
