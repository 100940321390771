import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col, Table } from 'reactstrap';
import * as Const from '../Const';
import Moment from 'react-moment';
import StatusError from './StatusError';
import {
	formatEnum,
	checkResponseJson,
	checkResponseBody,
} from './Utils.js';

import authHeader from '../services/auth-header';
import BinaryDocs from './BinaryDocs';
import XMLDocs from './XMLDocs';
import JsonDocs from './JsonDocs';

class ReceiptRepresentationDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
		this.goBack = this.goBack.bind(this);
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Receipt';

		const { vaultBasePath, receiptId, index } =
			this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}/receipts/${receiptId}/representations/${index}`;
		console.log(
			'ReceiptRepresentationDetail.componentDidMount: getUrl = ' +
				getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['status']);

				if (drhRsp.statusError) {
					console.error(
						'ReceiptRepresentationDetail.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			item: drhRsp.body,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	goBack() {
		console.log('ReceiptRepresentationDetail.goBack');
		this.props.history.goBack();
	}

	render() {
		const {
			item,
			httpInfo,
			statusError,
			isLoading,
			transactionId,
		} = this.state;
		const { vaultBasePath, receiptId, index } =
			this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>{'Receipt Representation'}</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}
		const merchantBasePath =
			item.merchandAndVaultInfo.merchantName.toLocaleLowerCase();
		const backLink = `/api/rest/vaults/${vaultBasePath}/receipts/${receiptId}`;
		const hasJsonFormat =
			item.content.valueJson &&
			item.content.valueJson !== 'undefined';
		const hasValueBinary =
			item.content.valueBinary &&
			item.content.valueBinary !== 'undefined';
		const hasValueText =
			item.content.valueText &&
			item.content.valueText !== 'undefined';
		const hasValueXML =
			item.content.valueXml &&
			item.content.valueXml !== 'undefined';
		const hasdepositFields =
			item.content.depositFields &&
			item.content.depositFields !== 'undefined';

		return (
			<div>
				<Container fluid style={{ paddingLeft: 40 }}>
					{title}
					<br />
					<h4>
						<b>Receipt Info</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Type</Col>
						<Col className="valMain">
							{item.receiptInfo.receiptType}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Receipt ID</Col>
						<Col className="hexIdGray">
							<b>{item.receiptInfo.receiptId}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time Created</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{item.receiptInfo.timeCreated}
							</Moment>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Index</Col>
						<Col className="hexIdGray">
							<b>{item.receiptInfo.index}</b>
						</Col>
					</Row>
					<br />

					<br />
					<h4>
						<b>{formatEnum('Merchand And Vault Info')}</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Merchant Name</Col>
						<Col className="valMain">
							{item.merchandAndVaultInfo.merchantName}
						</Col>
					</Row>

					<Row className="rowSpace">
						<Col xs="2">Vault Name</Col>
						<Col className="valMain">
							{String(item.merchandAndVaultInfo.vaultName)}
						</Col>
					</Row>
					<br />

					<br />
					<h4>
						<b>Processing</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Completed</Col>
						<Col className="valMain">
							{String(item.processing.completed)}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time Initiated</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{item.processing.timeInitiated}
							</Moment>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time Completed</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{item.processing.timeCompleted}
							</Moment>{' '}
						</Col>
					</Row>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>In Hub</b>
						<Row className="rowSpace">
							<Col xs="2">Produced by</Col>
							<Col className="hexId">
								<b>
									{formatEnum(
										item.processing.inHub.producedBy,
									)}
								</b>
							</Col>
						</Row>

						<Row className="rowSpace">
							<Col xs="2">Time produced</Col>
							<Col className="hexId">
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{item.processing.inHub.timeProduced}
								</Moment>{' '}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Storing status</Col>
							<Col className="hexId">
								{item.processing.inHub.storingStatus}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time stored</Col>
							<Col className="hexId">
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{item.processing.inHub.timeStored}
								</Moment>{' '}
							</Col>
						</Row>
					</Container>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>To Merchant</b>
						<Row className="rowSpace">
							<Col xs="2">Deposit status</Col>
							<Col className="hexId">
								<b>
									{formatEnum(
										item.processing.toMerchant
											.depositStatus,
									)}
								</b>
							</Col>
						</Row>
					</Container>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>To Vault</b>
						<Row className="rowSpace">
							<Col xs="2">Deposit status</Col>
							<Col className="hexId">
								<b>
									{formatEnum(
										item.processing.toVault.depositStatus,
									)}
								</b>
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Customer ID</Col>
							<Col className="hexId">
								{item.processing.toVault.customerID}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time planned</Col>
							<Col className="hexId">
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{item.processing.toVault.timePlanned}
								</Moment>{' '}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time deposit request </Col>
							<Col className="hexId">
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{
										item.processing.toVault
											.timeDepositRequest
									}
								</Moment>{' '}
							</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Time deposit response</Col>
							<Col className="hexId">
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{
										item.processing.toVault
											.timeDepositResponse
									}
								</Moment>{' '}
							</Col>
						</Row>
					</Container>
					<br />

					<br />

					<h4>
						<b>Content</b>
					</h4>
					<Container fluid style={{ paddingLeft: 20 }}>
						<b>Format</b>

						<Row className="rowSpace">
							<Col xs="2">Media type</Col>
							<Col className="valMain">
								{item.content.format.mediaType}
							</Col>
						</Row>

						<Row className="rowSpace">
							<Col xs="2">Conformance</Col>
							<Col>{item.content.format.conformance}</Col>
						</Row>
						<Row className="rowSpace">
							<Col xs="2">Version</Col>
							<Col>{item.content.format.version}</Col>
						</Row>
					</Container>

					<br />
					{hasdepositFields && (
						<Container fluid style={{ paddingLeft: 20 }}>
							<b>Deposit fields</b>

							<Row className="rowSpace">
								<Col xs="2">Title</Col>
								<Col>
									{item.content.depositFields.title}
								</Col>
							</Row>

							<Row className="rowSpace">
								<Col xs="2">Doc type</Col>
								<Col>{item.content.depositFields.type}</Col>
							</Row>
							<Row className="rowSpace">
								<Col xs="2">Author tags</Col>
								<Col>{item.content.depositFields.authorTags}</Col>
							</Row>
							<Row className="rowSpace">
								<Col xs="2">Hash algorithm</Col>
								<Col>{item.content.depositFields.algo}</Col>
							</Row>
							<Row className="rowSpace">
								<Col xs="2">Hash value</Col>
								<Col>{item.content.depositFields.hash}</Col>
							</Row>
							<Row className="rowSpace">
								<Col xs="2">File size</Col>
								<Col>{item.content.depositFields.size}</Col>
							</Row>
							<Row className="rowSpace">
								<Col xs="2">File name</Col>
								<Col>{item.content.depositFields.filename}</Col>
							</Row>
							<Row className="rowSpace">
								<Col xs="2">Valid until</Col>
								<Col>{item.content.depositFields.validUntil}</Col>
							</Row>
						</Container>
					)}
					{hasJsonFormat && (
						<div>
							<Row className="rowSpace">
								<b>Value JSON </b>
							</Row>
							<div>
								<JsonDocs
									id="json"
									name="json"
									merchantBasePath={merchantBasePath}
									jsonDocId={
										item.content.valueJson.jsonDocId
									}
								/>
							</div>
						</div>
					)}
					{hasValueText && (
						<div>
							<Container fluid style={{ paddingLeft: 20 }}>
								<Row className="rowSpace">
									<b>Value Text</b>
								</Row>
								<Row className="rowSpace">
									<div
										className="white-space-pre-line"
										style={{
											border: '1px solid rgba(0, 0, 0, 1)',
										}}
									>
										<div>
											<Container
												fluid
												style={{
													paddingLeft: 20,
													paddingRight: 20,
												}}
											>
												{' '}
												<p
													style={{
														'white-space': 'pre-wrap',
													}}
												>
													{
														item.content.valueText
															.textDoc
													}
												</p>
												{}{' '}
											</Container>
										</div>
									</div>
								</Row>
							</Container>
						</div>
					)}
					{hasValueXML && (
						<div>
							<Row className="rowSpace">
								<b>Value XML </b>
							</Row>
							<div>
								<XMLDocs
									id="xml"
									name="xml"
									merchantBasePath={merchantBasePath}
									xmlDocId={item.content.valueXml.xmlDocId}
								/>
							</div>
						</div>
					)}
					{hasValueBinary && (
						<Container fluid style={{ paddingLeft: 20 }}>
							<div>
								<b>Value Binary</b>

								<Row className="rowSpace">
									<Col xs="2">Binary Doc ID</Col>
									<Col className="hexId">
										{item.content.valueBinary.binaryDocId}
									</Col>
								</Row>
								<Row className="rowSpace">
									<Col xs="2">Hash algo</Col>
									<Col>
										<b>
											{item.content.valueBinary.hashAlgo}
										</b>
									</Col>
								</Row>
								<Row className="rowSpace">
									<Col xs="2">Hash value</Col>
									<Col>
										{item.content.valueBinary.hashValue}
									</Col>
								</Row>
								<div>
									<BinaryDocs
										id="binary"
										name="binary"
										merchantBasePath={merchantBasePath}
										binaryDocId={
											item.content.valueBinary.binaryDocId
										}
									/>
								</div>
							</div>
						</Container>
					)}

					<br />
					<Button color="secondary" tag={Link} to={backLink}>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(ReceiptRepresentationDetail);
