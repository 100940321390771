import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
	Button,
	Container,
	Row,
	Col
} from 'reactstrap';
import * as Const from '../Const';
import StatusError from './StatusError';
import { checkResponseJson, checkResponseBody } from './Utils.js';
import authHeader from '../services/auth-header';
import TransactionList from './TransactionList';


class MerchantTransactions extends Component {

	constructor(props) {
		super(props);
		this.state = {
            isLoading: true,
		};
	}

	async componentDidMount() {

		this.setState({isLoading: true});

        document.title = "MerchantTransactions";

		const {merchantBasePath} = this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}`;
		console.log("MerchantTransactions.componentDidMount: getUrl = " + getUrl);

		const response = await fetch(getUrl,
			{
				method: 'GET',
				headers: authHeader()
			});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError)  {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['status']);
				console.log("MerchantTransactions.componentDidMount: json = " + JSON.stringify(jsonBody));

				if (drhRsp.statusError) {
					console.error("MerchantTransactions.componentDidMount: json = " + JSON.stringify(jsonBody));
				}
			});
		}

		this.setState({ 
			body: drhRsp.body,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false 
		});
    }
    
	render() {
		
		const {body, httpInfo, statusError, isLoading} = this.state;
		const {merchantBasePath,merchantId} = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = (
			<h2>Merchant Transactions</h2>
		);

		if (statusError) {

			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br/>
						<StatusError id="statusError" name="statusError" statusError={statusError} httpInfo={httpInfo} />
					</Container>
				</div>
			);
		}

        
        let browserSearchParams = null;
        
        if (this.props.location) {
            browserSearchParams = new URLSearchParams(this.props.location.search);
            console.log("MerchantTransactions.render: browserSearchParams = " + browserSearchParams.toString()); 
        }
		const item = body.merchant;

		return (
			<div>

				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br/>
					<h4><b>Merchant</b></h4>
					<Row className="rowSpace">
						<Col xs="2">Merchant ID</Col>
						<Col className="hexIdGray">{item.merchantId}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Name</Col>
						<Col className="valMain">{item.name}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Vault</Col>
						<Col>{body.vault.name}</Col>
					</Row>
					 

					<br/>
					<br/>
					<h4><b>Transactions</b></h4>

                    <TransactionList id="transactions" name= "transactions" merchantBasePath={merchantBasePath} merchantId={merchantId} 
                        browserSearchParams={browserSearchParams}/>
					
					<br/>
					<Button
						color="secondary"
						tag={Link}
						to={`/api/rest/merchants/${merchantBasePath}`}
						>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(MerchantTransactions);
