import React, { Component } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Const from '../Const';
import StatusError from './StatusError';
import {
	formatEnum,
	getLevelClass,
	checkResponseJson,
	checkResponseBody,
	loadPaginationConfig,
	queryInfoDefault,
	searchParamsToQueryInfo,
	queryInfoToSearchParams,
	queryInfoRowCountStr,
} from './Utils.js';
import authHeader from '../services/auth-header';
import Pagination from 'react-js-pagination';
import Moment from 'react-moment';
import ReceiptFilter from './ReceiptFilter';

class VaultReceiptList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			modal: false,
			queryInfo: queryInfoDefault(),
			filterModal: {
				title: 'My Title',
				body: 'Hello',
				data: [],
				onHide: this.onFilterModalHide,
				onCancel: this.onFilterModalCancel,
				onValidate: this.onFilterModalValidate,
			},
		};

		this.loadList = this.loadList.bind(this);
		this.onPageActiveChange = this.onPageActiveChange.bind(this);
		this.onListRefresh = this.onListRefresh.bind(this);

		this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
		this.onFilterModalCancel = this.onFilterModalCancel.bind(this);
		this.onFilterModalValidate = this.onFilterModalValidate.bind(
			this,
		);
		this.onFilterModalHide = this.onFilterModalHide.bind(this);

		this.paginationProps = loadPaginationConfig();
		this.paginationProps.onChange = this.onPageActiveChange;
	}

	async componentDidMount() {
		console.log('VaultReceiptList.componentDidMount');

		const queryInfo = searchParamsToQueryInfo(
			this.props.browserSearchParams,
		);

		this.loadList(queryInfo);
	}

	onListRefresh() {
		this.loadList(this.state.queryInfo);
	}

	onPageActiveChange(pageActive) {
		console.log(
			'VaultReceiptList.onPageActiveChange: pageActive = ' +
				pageActive,
		);

		if (!pageActive) return;

		const { queryInfo } = this.state;

		queryInfo.paginationInfo.pageActive = pageActive;

		this.loadList(queryInfo);
	}

	onFilterButtonClick() {
		console.log('VaultReceiptList.onFilterButtonClick');

		this.setState({
			modal: true,
		});
	}

	onFilterModalValidate(filterInfo) {
		if (!filterInfo) return;

		const { queryInfo } = this.state;
		queryInfo.filterInfo = filterInfo;

		this.setState({
			modal: false,
			queryInfo: queryInfo,
		});

		this.loadList(queryInfo);
	}

	onFilterModalCancel() {
		console.log('VaultReceiptList.onFilterModalCancel');

		this.setState({
			modal: false,
		});
	}

	onFilterModalHide(msg) {
		console.log('VaultReceiptList.onFilterModalHide: msg = ' + msg);
	}

	async loadList(queryInfo) {
		console.log('VaultReceiptList.loadList');

		this.setState({
			isLoading: true,
		});

		let queryComponent = '';
		let urlSearchParams = queryInfoToSearchParams(queryInfo);
		if (urlSearchParams)
			queryComponent = '?' + urlSearchParams.toString();

		const { vaultBasePath } = this.props;

		let getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}/receipts${queryComponent}`;

		console.log('VaultReceiptList.loadList: getUrl = ' + getUrl);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['receipts']);

				if (drhRsp.statusError) {
					console.error(
						'VaultReceiptList.loadList: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		if (drhRsp.body) {
			const { paginationInfo } = drhRsp.body;

			if (paginationInfo) {
				// when defined, server-returned PaginationInfo can completely override the existing one
				queryInfo.paginationInfo = paginationInfo;

				console.log(
					`VaultReceiptList.loadList: paginationInfo = ${JSON.stringify(
						paginationInfo,
					)}`,
				);

				this.paginationProps.activePage =
					paginationInfo.pageActive;
				this.paginationProps.itemsCountPerPage =
					paginationInfo.pageSize;
				this.paginationProps.totalItemsCount =
					paginationInfo.rowCountInTotal;
			}
		}

		this.setState({
			items: drhRsp.body ? drhRsp.body.receipts : null,
			queryInfo: queryInfo,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	render() {
		const {
			items,
			httpInfo,
			statusError,
			isLoading,
			filterModal,
			queryInfo,
			modal,
		} = this.state;
		const { vaultBasePath } = this.props;

		console.log(`VaultReceiptList.render: modal:${modal}`);

		if (isLoading) {
			return <p>Loading...</p>;
		}

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		const itemList = items.map((item) => {
			const viewLink = `/api/rest/vaults/${vaultBasePath}/receipts/${item.receiptId}`;

			return (
				<tr key={item.receiptId}>
					<td title={item.receiptId} className="hexIdGray">
						{item.receiptId.substring(0, 4) + '\u2026'}
					</td>
					<td>
						<b>{item.merchantName}</b>
					</td>
					<td className="valMain fitwidth">
						{item.receiptType}
					</td>
					<td className="state">
						<Moment format="YYYY-MM-DD HH:mm:ss">
							{item.timeCreated}
						</Moment>
					</td>
					<td
						className={getLevelClass(item.depositStatus)}
						style={{ width: 40, textAlign: 'left' }}
						title={item.depositStatus}
					>
						{formatEnum(item.depositStatus)}
					</td>

					<td>
						<Moment format="YYYY-MM-DD HH:mm:ss">
							{item.timeDeposited}
						</Moment>
					</td>
					<td>
						<Button
							size="sm"
							color="secondary"
							tag={Link}
							to={viewLink}
						>
							View
						</Button>
					</td>
				</tr>
			);
		});

		const rowCountStr = queryInfoRowCountStr(queryInfo);

		return (
			<div>
				<Container>
					<Button
						size="sm"
						color="secondary"
						title="Refresh"
						onClick={() => this.onListRefresh()}
					>
						{'\u21BB'}
					</Button>
					&nbsp;&nbsp;
					<Button
						size="sm"
						color="secondary"
						onClick={() => this.onFilterButtonClick()}
					>
						Filters...
					</Button>
					<span style={{ color: 'silver', marginLeft: '20px' }}>
						{rowCountStr}
					</span>
					<br />
					<br />
					<Pagination {...this.paginationProps} />
					<ReceiptFilter
						show={this.state.modal}
						title={filterModal.title}
						body={filterModal.body}
						filterInfo={queryInfo.filterInfo}
						onHide={filterModal.onHide.bind(this)}
						onCancel={filterModal.onCancel.bind(this)}
						onValidate={filterModal.onValidate.bind(this)}
					/>
					<Table className="mt-4" width="1300px">
						<thead>
							<tr>
								<th width="60px">Receipt ID</th>
								<th width="60px">Merchant</th>
								<th width="110px">Type</th>
								<th width="110px">Time created</th>
								<th width="110px">Deposit status</th>
								<th width="70px">Time deposited</th>
								<th width="120px">Actions</th>
							</tr>
						</thead>
						<tbody>{itemList}</tbody>
					</Table>
					<br />
				</Container>
			</div>
		);
	}
}

export default VaultReceiptList;
