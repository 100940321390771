import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import StatusError from './StatusError';
import { checkResponseJson, checkResponseBody } from './Utils.js';
import authHeader from '../services/auth-header';

const Const = require('../Const');

class VaultList extends Component {
	constructor(props) {
		super(props);
		this.state = { items: [], isLoading: true };
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Vaults';

		const getUrl = `${Const.API_BASE_PATH}`;
		console.log('VaultList.componentDidMount: getUrl = ' + getUrl);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['vaults']);

				if (drhRsp.statusError) {
					console.error(
						'VaultList.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			isLoading: false,
			items: drhRsp.body ? drhRsp.body.vaults : null,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
		});
	}

	render() {
		const { items, httpInfo, statusError, isLoading } = this.state;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		if (statusError) {
			console.error(
				'VaultList.render: httpInfo.status = ' + httpInfo.status,
			);

			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						<div>
							{httpInfo.status === 401 ? (
								'Login required.'
							) : (
								<div>
									<br />
									<StatusError
										id="statusError"
										name="statusError"
										statusError={statusError}
										httpInfo={httpInfo}
									/>
								</div>
							)}
						</div>
					</Container>
				</div>
			);
		}

		const itemList = items.map((item) => {
			const viewLink = `/api/rest/vaults/${item.basePath}`;

			return (
				<tr key={item.merchantId}>
					<td className="hexIdGray">{item.vaultId}</td>
					<td className="valMain fitwidth">{item.name}</td>
					<td className="fitwidth">/{item.basePath}</td>
					<td>
						<Button
							size="sm"
							color="secondary"
							tag={Link}
							to={viewLink}
						>
							View
						</Button>
					</td>
				</tr>
			);
		});

		return (
			<div>
				<Container>
					<Table className="mt-4" width="1070px">
						<thead>
							<tr>
								<th width="120px">ID</th>
								<th width="170px">Name</th>
								<th width="170px">Base Path</th>
								<th width="170px">Actions</th>
							</tr>
						</thead>
						<tbody>{itemList}</tbody>
					</Table>
				</Container>
			</div>
		);
	}
}

export default VaultList;
