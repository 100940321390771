import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
	Row,
	Col
} from 'reactstrap';

class StatusError extends Component {


	render() {
		
		console.log("StatusError.render: IN");

		let {httpInfo, statusError} = this.props;

		if (!httpInfo)
			httpInfo = {}; // should not happen

		if (!statusError)
			statusError = {}; // should not happen

		const httpStyle = httpInfo.ok ? "statusOk" : "statusErr";

		const httpError = statusError.component === 'http';

		return (
			<div>
				<h4>HTTP Status</h4>

				<Row className="rowSpace">
					<Col xs="2" style={{width: '40px'}}>URL</Col>
					<Col>{httpInfo.url}</Col>
				</Row>
				<Row className="rowSpace">
					<Col xs="2" style={{width: '40px'}}>Status</Col>
					<Col className={httpStyle}>{httpInfo.status}</Col>
				</Row>
				<Row className="rowSpace">
					<Col xs="2" style={{width: '40px'}}>Status Text</Col>
					<Col className={httpStyle}>{httpInfo.statusText}</Col>
				</Row>
				<Row className="rowSpace">
					<Col xs="2" style={{width: '40px'}}>Content Type</Col>
					<Col>{httpInfo.contentType}</Col>
				</Row>

				<br/>
				<h4>DRH Status</h4>
				<Row className="rowSpace">
					<Col xs="2" style={{width: '40px'}}>Component</Col>
					<Col className="statusErr">{httpError ? '' : statusError.component}</Col>
				</Row>
				<Row className="rowSpace">
					<Col xs="2" style={{width: '40px'}}>Error</Col>
					<Col className="statusErr">{httpError ? '' : statusError.error}</Col>
				</Row>
			</div>
		);

	}
}

export default withRouter(StatusError);
