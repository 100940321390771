import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Const from '../Const';
import StatusError from './StatusError';
import {getLevelClass,
	checkResponseJson,
	checkResponseBody,
	loadPaginationConfig,
	queryInfoDefault,
	searchParamsToQueryInfo,
	queryInfoToSearchParams,
	queryInfoRowCountStr,
	formatEnum,
} from './Utils.js';
import authHeader from '../services/auth-header';
import Pagination from 'react-js-pagination';
import CrmTokenFilter from './CrmTokenFilter';
import Moment from 'react-moment';

class CrmTokenList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			modal: false,
			queryInfo: queryInfoDefault(),
			filterModal: {
				title: 'My Title',
				body: 'Hello',
				data: [],
				onHide: this.onFilterModalHide,
				onCancel: this.onFilterModalCancel,
				onValidate: this.onFilterModalValidate,
			},
		};

		this.loadList = this.loadList.bind(this);
		this.onPageActiveChange = this.onPageActiveChange.bind(this);
		this.onListRefresh = this.onListRefresh.bind(this);

		this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
		this.onFilterModalCancel = this.onFilterModalCancel.bind(this);
		this.onFilterModalValidate = this.onFilterModalValidate.bind(
			this,
		);
		this.onFilterModalHide = this.onFilterModalHide.bind(this);

		this.paginationProps = loadPaginationConfig();
		this.paginationProps.onChange = this.onPageActiveChange;
	}

	async componentDidMount() {
		console.log('CrmTokenList.componentDidMount');

		const queryInfo = searchParamsToQueryInfo(
			this.props.browserSearchParams,
		);

		this.loadList(queryInfo);
	}

	onListRefresh() {
		this.loadList(this.state.queryInfo);
	}

	onPageActiveChange(pageActive) {
		console.log(
			'CrmTokenList.onPageActiveChange: pageActive = ' +
				pageActive,
		);

		if (!pageActive) return;

		const { queryInfo } = this.state;

		queryInfo.paginationInfo.pageActive = pageActive;

		this.loadList(queryInfo);
	}

	onFilterButtonClick() {
		console.log('CrmTokenList.onFilterButtonClick');

		this.setState({
			modal: true,
		});
	}

	onFilterModalValidate(filterInfo) {
		if (!filterInfo) return;

		const { queryInfo } = this.state;
		queryInfo.filterInfo = filterInfo;

		this.setState({
			modal: false,
			queryInfo: queryInfo,
		});

		this.loadList(queryInfo);
	}

	onFilterModalCancel() {
		console.log('CrmTokenList.onFilterModalCancel');

		this.setState({
			modal: false,
		});
	}

	onFilterModalHide(msg) {
		console.log('CrmTokenList.onFilterModalHide: msg = ' + msg);
	}

	async loadList(queryInfo) {
		console.log('CrmTokenList.loadList');

		this.setState({
			isLoading: true,
		});

		let queryComponent = '';
		let urlSearchParams = queryInfoToSearchParams(queryInfo);
		if (urlSearchParams)
			queryComponent = '?' + urlSearchParams.toString();

		const { merchantBasePath } = this.props;

		let getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/crmTokens/${queryComponent}`;

		console.log('CrmTokenList.loadList: getUrl = ' + getUrl);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['crmTokens']);

				if (drhRsp.statusError) {
					console.error(
						'CrmTokenList.loadList: json = ' +
							JSON.stringify(jsonBody),
					);
				} else {
					//console.log("CrmTokenList.loadList: json = " + JSON.stringify(jsonBody));
				}
			});
		}

		if (drhRsp.body) {
			const { paginationInfo } = drhRsp.body;

			if (paginationInfo) {
				// when defined, server-returned PaginationInfo can completely override the existing one
				queryInfo.paginationInfo = paginationInfo;

				console.log(
					`CrmTokenList.loadList: paginationInfo = ${JSON.stringify(
						paginationInfo,
					)}`,
				);

				this.paginationProps.activePage =
					paginationInfo.pageActive;
				this.paginationProps.itemsCountPerPage =
					paginationInfo.pageSize;
				this.paginationProps.totalItemsCount =
					paginationInfo.rowCountInTotal;
			}
		}

		this.setState({
			items: drhRsp.body ? drhRsp.body.crmTokens : null,
			queryInfo: queryInfo,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			isLoading: false,
		});
	}

	render() {
		const {
			items,
			httpInfo,
			statusError,
			isLoading,
			filterModal,
			queryInfo,
			modal,
		} = this.state;
		const { merchantBasePath } = this.props;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}
		const addLink = `/api/rest/merchants/${merchantBasePath}/crmTokens/new/form`;

		const itemList = items.map((item) => {
			const viewLink = `/api/rest/merchants/${merchantBasePath}/crmTokens/${item.crmTokenId}`;

 
			return (
				<tr key={item.crmTokenId}>
					<td title={item.crmTokenId} className="hexIdGray">
						{item.crmTokenId.substring(0, 4) + '\u2026'}
					</td>
					<td className="valmain"><b>{item.tokenValue}</b></td>
					<td>{formatEnum(item.firstTokenizedBy)}</td>
					<td>{item.transactionCount}</td>
					<td
						className={getLevelClass(
							item.customerAssignment,
						)}
						style={{
							width: 40,
							textAlign: 'left',
						}}
					>
					 {formatEnum(item.customerAssignment)}
 
					</td>
					<td>
						<Moment format="YYYY-MM-DD HH:mm:ss">
							{item.timeTokenized}
						</Moment>
					</td>

					<td>
						<ButtonGroup>
							<Button
								size="sm"
								color="secondary"
								tag={Link}
								to={viewLink}
							>
								View
							</Button>

							<div>&nbsp;</div>

							<Button size="sm" color="danger" disabled={true}>
								Delete
							</Button>
						</ButtonGroup>
					</td>
				</tr>
			);
		});
		const rowCountStr = queryInfoRowCountStr(queryInfo);

		return (
			<div>
				<div style={{paddingBottom: '15px'}} className="float-right">
						<Button color="success" tag={Link} to={addLink}>Add CrmToken</Button>
					</div>
				<Container>
					<br />
					<Button
						size="sm"
						color="secondary"
						title="Refresh"
						onClick={() => this.onListRefresh()}
					>
						{'\u21BB'}
					</Button>
					&nbsp;&nbsp;
					<Button
						size="sm"
						color="secondary"
						onClick={() => this.onFilterButtonClick()}
					>
						Filters...
					</Button>
					<span style={{ color: 'silver', marginLeft: '20px' }}>
						{rowCountStr}
					</span>
					<br />
					<br />
					<Pagination {...this.paginationProps} />
					<CrmTokenFilter
						show={this.state.modal}
						title={filterModal.title}
						body={filterModal.body}
						filterInfo={queryInfo.filterInfo}
						onHide={filterModal.onHide.bind(this)}
						onCancel={filterModal.onCancel.bind(this)}
						onValidate={filterModal.onValidate.bind(this)}
					/>
					<Table>
						<thead>
							<tr>
								<th style={{ width: '40px' }}>ID</th>
								<th style={{ width: '40px' }}>Token Value</th>
								<th style={{ width: '40px' }}>
									First tokenized by
								</th>
								<th style={{ width: '40px' }}>
									Transaction count
								</th>
								<th style={{ width: '40px' }}>
									Assignment to customer
								</th>
								<th style={{ width: '40px' }}>
									Time Tokenized
								</th>
								<th width="100px">Actions</th>
							</tr>
						</thead>
						<tbody>{itemList}</tbody>
					</Table>
					<br />
					<Button
						color="secondary"
						tag={Link}
						to={`/api/rest/merchants/${merchantBasePath}`}
					>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default CrmTokenList;
