import React, { Component } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import * as Const from '../Const';
import StatusError from './StatusError';
import { checkResponseJson, checkResponseBody } from './Utils.js';
import authHeader from '../services/auth-header';
import Moment from 'react-moment';

class MerchantDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}
	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Merchant';

		const { merchantBasePath } = this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}`;

		console.log(
			'MerchantDetail.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['merchant']);

				if (drhRsp.statusError) {
					console.error(
						'MerchantDetail.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			isLoading: false,
			body: drhRsp.body,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
		});
	}

	render() {
		const { body, httpInfo, statusError, isLoading } = this.state;
		const { merchantBasePath } = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>{'View Merchant'}</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		const item = body.merchant;
		const vault = body.vault;

		console.log('MerchantHome.render: name = ' + item.name);

		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br />
					<h4>
						<b>Merchant</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Merchant ID</Col>
						<Col className="hexIdGray">{item.merchantId}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Name</Col>
						<Col className="valMain">{item.name}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Base Path</Col>
						<Col>{'/' + item.basePath}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time created</Col>

						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{item.timeCreated}
							</Moment>
						</Col>
					</Row>
					<br />
					<br />
					<h4>
						<b>Vault</b>
					</h4>
				 
					<Row className="rowSpace">
						<Col xs="2">Name</Col>
						<Col className="valMain">{vault.name}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Base Path</Col>
						<Col>{'/' + vault.basePath}</Col>
					</Row>
					<br />
					<br />
					<h4>
						<b>Resources</b>
					</h4>

					<Button color="link" size="lg">
						<Link
							to={`/api/rest/merchants/${merchantBasePath}/crmTokens`}
						>
							CRM Tokens
						</Link>
					</Button>
					<br />

					<Button color="link" size="lg">
						<Link
							to={`/api/rest/merchants/${merchantBasePath}/transactions`}
						>
							Transactions
						</Link>
					</Button>
					<br />

					<br />
					<br />
					<br />

					<Button
						color="secondary"
						tag={Link}
						to={`/api/rest/vaults/${vault.basePath}`}
					>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default MerchantDetail;
