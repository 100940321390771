import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import * as Const from '../Const';
import StatusError from './StatusError';
import {
	formatEnum,
	checkResponseJson,
	checkResponseBody,
} from './Utils.js';
import authHeader from '../services/auth-header';
import Moment from 'react-moment';
import CrmTokenTransactionList from './CrmTokenTransactionList';
class CrmTokenDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}
	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Crm Token';

		let transactionId = null;
		let consentFlowId = null;
		const {
			merchantBasePath,
			crmTokenId,
		} = this.props.match.params;

		const queryParams = new URLSearchParams(
			this.props.location.search,
		);
		if (queryParams) {
			transactionId = queryParams.get('transactionId');
			consentFlowId = queryParams.get('consentFlowId');
		}
		const getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}/crmTokens/${crmTokenId}`;

		console.log(
			'CrmTokenDetail.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['status']);

				if (drhRsp.statusError) {
					console.error(
						'CrmTokenDetail.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}
		let backLink = '/';

		if (transactionId) {
			// TODO: make it possible to go back to 'View Transaction'
			backLink = `/api/rest/merchants/${merchantBasePath}/transactions/${transactionId}  `;
		} else if (consentFlowId) {
			// TODO: make it possible to go back to 'View ConsentFlow'
			backLink = `/api/rest/vaults/${drhRsp.body.merchantInfo.vaultBaseName}/consentFlows/${consentFlowId} `;
		} else {
			backLink = `/api/rest/merchants/${merchantBasePath}/crmTokens`;
		}
		this.setState({
			item: drhRsp.body,
			backLink: backLink,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
			consentFlowId: consentFlowId,
			transactionId: transactionId,

			isLoading: false,
		});
	}

	render() {
		const {
			consentFlowId,
			transactionId,
			item,
			httpInfo,
			backLink,
			statusError,
			isLoading,
		} = this.state;
		const {
			merchantBasePath,
			crmTokenId,
		} = this.props.match.params;
		const constFlowLink = `/api/rest/merchants/${merchantBasePath}/crmTokens/${crmTokenId}?transactionId=${transactionId}`;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>{'View CRM Token '}</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		let browserSearchParams = null;

		if (this.props.location)
			browserSearchParams = new URLSearchParams(
				this.props.location.search,
			);

		console.log(
			'CrmTokenDetail.render: name = ' +
				item.name +
				', browserSearchParams = ' +
				browserSearchParams,
		);
		const hashubDocHandling  =
		item.hubDocHandling  &&
		item.hubDocHandling  !== 'undefined';
		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br />
					<h4>
						<b>CRM Token Info </b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">CRM Token ID</Col>
						<Col className="hexIdGray">
							{item.tokenization.crmTokenId}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Vault name</Col>
						<Col className="valMain">
							{item.merchantInfo.vaultName}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Merchant name</Col>
						<Col className="valMain">
							{item.merchantInfo.merchantName}
						</Col>
					</Row>

					<br />
					<br />

					{hashubDocHandling && (
						<>
							<h4>
								<b>Doc Handling (by Hubs)</b>
							</h4>
							{item.hubDocHandling.createdBy && (<>	<Row className="rowSpace">
								<Col xs="2">Created  by</Col>
								<Col className="hexIdGray">
									{item.hubDocHandling.createdBy}
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.createdAt && (<>
							<Row className="rowSpace">
								<Col xs="2">Created  at</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{item.hubDocHandling.createdAt}
									</Moment>
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.updatedBy && (<>
							<Row className="rowSpace">
								<Col xs="2">Updated by</Col>
								<Col className="hexIdGray">
									{item.hubDocHandling.updatedBy}
								</Col>
							</Row>
							</>)}
							{item.hubDocHandling.updatedAt && (<>
							<Row className="rowSpace">
								<Col xs="2">Updated at</Col>
								<Col className="hexId">
									<Moment format="YYYY-MM-DD HH:mm:ss">
										{item.hubDocHandling.updatedAt}
									</Moment>
								</Col>
							</Row>
							</>)}
							<br />
							<br />
						</>
					)}
					<h4>
						<b>Tokenization</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">First tokenized by</Col>
						<Col>
							<b>
								{formatEnum(
									item.tokenization.firstTokenizedBy,
								)}
							</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time Tokenized</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{item.tokenization.timeTokenized}
							</Moment>
						</Col>{' '}
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Token Value</Col>
						<Col>
							<b>{item.tokenization.tokenValue}</b>
						</Col>
					</Row>

					<br />
					<br />

					<h4>
						<b>Customer Info</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">State</Col>
						<Col>
							<b>
								{formatEnum(
									item.customerInfo.assignmentState,
								)}
							</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Customer ID</Col>
						<Col className="hexId">
							{item.customerInfo.customerId}
						</Col>{' '}
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time Assigned</Col>
						<Col className="hexId">
							<Moment format="YYYY-MM-DD HH:mm:ss">
								{item.customerInfo.timeAssigned}
							</Moment>
						</Col>{' '}
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Consent Flow ID</Col>
						<Col className="hexId">{consentFlowId}</Col>
						&nbsp; &nbsp;
						{consentFlowId && (
							<Button
								color="secondary"
								tag={Link}
								target="_blank"
								size="sm"
								to={constFlowLink}
							>
								{'\u3009'}
							</Button>
						)}
					</Row>
					<br />
					<br />
					<h4>
						<b>Transactions</b>
					</h4>

					<CrmTokenTransactionList
						id="crmTokenTransactionList"
						name="crmTokenTransactionList"
						merchantBasePath={merchantBasePath}
						crmTokenId={crmTokenId}
						browserSearchParams={browserSearchParams}
					/>

					<br />
					<br />
					<Button color="secondary" tag={Link} to={backLink}>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(CrmTokenDetail);
