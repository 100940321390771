// !!!! must be inline with package.json >proxy"

const CLIENT_VERSION = '2022-05-25 (Build 0018)';

const LOCAL_HTTP = 1;
const CLOUD_HTTP = 2;
const CLOUD_HTTPS = 3;

const deployment = CLOUD_HTTPS;

let DRH_ADDRESS = 'localhost';
let DRH_PORT = '8099';
let WEB_CONTEXT = '';
let DRH_PROTOCOL = '';

switch (deployment) {
	case LOCAL_HTTP:
		DRH_PROTOCOL = 'http';
		DRH_ADDRESS = 'localhost';
		DRH_PORT = ':8100';
		WEB_CONTEXT = '';
		break;

	case CLOUD_HTTP:
		DRH_PROTOCOL = 'https';
		DRH_ADDRESS = 'dts.us.ilabs.tn';
		//DRH_ADDRESS = 'ec2-54-163-45-146.compute-1.amazonaws.com';
		//	DRH_ADDRESS = '##DRH_MACHINE_IP_ADDRESS##';
		DRH_PORT = ':8100';
		WEB_CONTEXT = '/DigitalReceiptHub';
		break;

	case CLOUD_HTTPS:
	default:
		DRH_PROTOCOL = 'https';
        DRH_ADDRESS = 'dts.us.ilabs.tn';
		DRH_PORT = ':8100';
		WEB_CONTEXT = '/DigitalReceiptHub';
		break;
}

const DRH_ENDPOINT_BASE = `${DRH_PROTOCOL}://${DRH_ADDRESS}${DRH_PORT}${WEB_CONTEXT}`;
const DRH_ENDPOINT_API_REST = `${DRH_ENDPOINT_BASE}/api/rest`;
const DRH_ENDPOINT_API_REST_MERCHANTS = `${DRH_ENDPOINT_API_REST}/merchants`;
const DRH_ENDPOINT_API_REST_VAULTS = `${DRH_ENDPOINT_API_REST}/vaults`;
module.exports = Object.freeze({
	CLIENT_VERSION: CLIENT_VERSION,
	DRH_ENDPOINT_BASE: DRH_ENDPOINT_BASE,
	API_BASE_PATH: DRH_ENDPOINT_API_REST,
	API_BASE_PATH_MERCHANTS: DRH_ENDPOINT_API_REST_MERCHANTS,
	API_BASE_PATH_VAULTS: DRH_ENDPOINT_API_REST_VAULTS,

	PROMPTED_TEXT_TRUNC: 70,
	UUID_NIL: '00000000-0000-0000-0000-000000000000',
});
