import React, { Component } from 'react';
import {  withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import * as Const from '../Const';
import StatusError from './StatusError';
import CrmTokenList from './CrmTokenList';
import { checkResponseJson, checkResponseBody } from './Utils.js';
import authHeader from '../services/auth-header';
class MerchantCrmTokens extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Merchant CRM Tokens';

		const { merchantBasePath } = this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH_MERCHANTS}/${merchantBasePath}`;
		console.log(
			'MerchantCrmTokens.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhrsp = checkResponseJson(response);
		console.log(
			'MerchantCrmTokens.componentDidMount: json = ' +
				JSON.stringify(response),
		);

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['vault']);

				if (drhRsp.statusError) {
					console.error(
						'MerchantCrmTokens.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			isLoading: false,
			body: drhRsp.body,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
		});
	}

	render() {
		const { body, httpInfo, statusError, isLoading } = this.state;
		const { merchantBasePath } = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>Merchant CRM Tokens </h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		const item = body.merchant;
		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br />
					<h4>
						<b>Merchant </b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Merchant ID</Col>
						<Col className="hexIdGray">{item.merchantId}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Name</Col>
						<Col className="valMain fitwidth">{item.name}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Vault </Col>
						<Col className="fitwidth">
							<b>{body.vault.name}</b>
						</Col>
					</Row>

					<br />
					<br />
					<br />
					<h4>
						<b>CRM Tokens</b>
					</h4>
					<CrmTokenList
						id="crmTokens"
						merchantBasePath={merchantBasePath}
						name="crmTokens"
					/>
				</Container>
			</div>
		);
	}
}

export default withRouter(MerchantCrmTokens);
