import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import * as Const from '../Const';
import StatusError from './StatusError';
import { checkResponseJson, checkResponseBody } from './Utils.js';
import authHeader from '../services/auth-header';
import Moment from 'react-moment';

class HubInstanceDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'HubInstance';

		const { hubInstanceId } = this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH}/common/hubInstances/${hubInstanceId}`;
		console.log(
			'HubInstanceDetail.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhrsp = checkResponseJson(response);
		console.log(
			'HubInstanceDetail.componentDidMount: json = ' +
				JSON.stringify(response),
		);

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['hubInstance']);

				if (drhRsp.statusError) {
					console.error(
						'HubInstanceDetail.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			isLoading: false,
			body: drhRsp.body,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
		});
	}

	render() {
		const { body, httpInfo, statusError, isLoading } = this.state;
		const { hubInstanceId } = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>View Hub Instance </h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		const item = body.hubInstance;
		const hasFreeMemory  =		item.memoryUsage &&
		item.memoryUsage !== 'undefined';
		console.log('HubInstanceDetail.render: name = ' + item.name);
		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br />
					<Row className="rowSpace">
						<Col xs="2">Global ID</Col>
						<Col className="valMain fitwidth">{item.id}</Col>
					</Row>
					<br />
					<br />
					<h4>
						<b>Machine </b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Machine type</Col>
						<Col className="hexIdGray">
							{item.machine.machineType}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Operating system</Col>
						<Col className="hexIdGray ">
							{item.machine.operatingSystem}
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Instance ID </Col>
						<Col className="fitwidth">
							<b>{item.machine.instanceId}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Region </Col>
						<Col className="fitwidth">
							<b>{item.machine.region}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Machine specs </Col>
						<Col className="fitwidth">
							<b>{item.machine.machineSpecs}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Time Started</Col>
						<Col className="hexId">
							{
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{item.machine.timeStarted}
								</Moment>
							}
						</Col>
					</Row>
					<br />
					<h4>
						<b>Web App </b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Time First Started</Col>
						<Col className="hexId">
							{
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{item.webApp.timeFirstStarted}
								</Moment>
							}
						</Col>
					</Row>{' '}
					<Row className="rowSpace">
						<Col xs="2">Time Last Started</Col>
						<Col className="hexId">
							{
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{item.webApp.timeLastStarted}
								</Moment>
							}
						</Col>
					</Row>{' '}
					<Row className="rowSpace">
						<Col xs="2">Time Last Running</Col>
						<Col className="hexId">
							{
								<Moment format="YYYY-MM-DD HH:mm:ss">
									{item.webApp.timeLastRunning}
								</Moment>
							}
						</Col>
					</Row>
					<br />
					<br />
					{hasFreeMemory	&&(		<h4>
						<b>Memory Usage</b>
					</h4>)}
					{hasFreeMemory	&&(<Row className="rowSpace">
						<Col xs="2">Free memory</Col>
						<Col className="hexId">
							{Number(item.memoryUsage.freeMemory).toLocaleString() + ' bytes'}
						</Col>
					</Row>)}{' '}
					{item.memoryUsage	&&(	<Row className="rowSpace">
						<Col xs="2">Total memory</Col>
						<Col className="hexId">
							{Number(item.memoryUsage.totalMemory).toLocaleString() + ' bytes'}
						</Col>
					</Row>)}{' '}
					{item.memoryUsage &&(<Row className="rowSpace">
						<Col xs="2">Max memory</Col>
						<Col className="hexId">
							{Number(item.memoryUsage.maxMemory).toLocaleString() + ' bytes'}
						</Col>
					</Row>)}
					{item.memoryUsage &&(	<Row className="rowSpace">
						<Col xs="2">Usage</Col>
						<Col className="fitwidth">
						<b>	{item.memoryUsage.pctUsed + ' %'}</b>
						</Col>
					</Row>)}
					{item.memoryUsage &&(		<Row className="rowSpace">
						<Col xs="2">Usage peak</Col>
						<Col className="fitwidth">
						<b>	{item.memoryUsage.pctUsedPeak + ' %'}</b>
						</Col>
					</Row>)}
						{item.memoryUsage &&(
 		<Row className="rowSpace">
						<Col xs="2">Usage max accepted</Col>
						<Col className="hexId">
							{item.memoryUsage.pctUsedMaxAccepted + ' %'}
						</Col>
					</Row>)}
					<br />
					<br />
					<br />
					<Button
						color="secondary"
						tag={Link}
						to={`/api/rest/common/hubInstances`}
					>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(HubInstanceDetail);
