import React, { Component } from 'react';

import {
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
} from 'reactstrap';

class HubInstanceFilter extends Component {
	constructor(props) {
		super(props);

		const { filterInfo } = this.props;

		this.state = {
			isLoading: true,
			filterInfo: this.props.filterInfo,
		};

		this.state = {
			filterInfo: filterInfo,
		};

		console.log(
			'HubInstanceFilter.constructor: show = ' + this.props.show,
		);
	}

	async componentDidMount() {
		console.log('HubInstanceFilter.componentDidMount');
	}

	onToggleEnumMachineType = (selected) => {
		console.log(
			'HubInstanceFilter.onToggleEnumMachineType: selected = ' +
				selected,
		);

		const { filterInfo } = this.state;
		const { machineType } = filterInfo;

		machineType[selected]['active'] = !machineType[selected][
			'active'
		];

		this.setState({
			filterInfo: filterInfo,
		});
	};

	onToggleEnumRunningState = (selected) => {
		console.log(
			'HubInstanceFilter.onToggleEnumRunningState: selected = ' +
				selected,
		);

		const { filterInfo } = this.state;
		const { runningState } = filterInfo;

		runningState[selected]['active'] = !runningState[selected][
			'active'
		];

		this.setState({
			filterInfo: filterInfo,
		});
	};

	getButtonColor = (selected) => {
		if (selected)
			return {
				backgroundColor: '#f1c1f1',
				color: 'black',
				marginLeft: '6px',
				paddingRight: '6px',
			};

		return {
			backgroundColor: '#f1f1f1',
			color: 'black',
			marginLeft: '6px',
			paddingRight: '6px',
		};
	};

	onValidate = () => {
		console.log('HubInstanceFilter.onValidate');
		this.props.onValidate(this.state.filterInfo);
	};

	onCancel = () => {
		console.log('HubInstanceFilter.onCancel');
		this.props.onCancel();
	};

	getColorClass = (inputState) => {
		if (inputState) {
			if (inputState === 'OK') return 'inputValOk';
			if (inputState === 'NOK') return 'inputValErr';
		}

		return 'inputValEmpty';
	};

	render() {
		console.log(
			`HubInstanceFilter.render: show = ${this.props.show}`,
		);

		const { filterInfo, inputState } = this.state;

		let machineTypeList = '';
		let runningStateList = '';

		const { machineType, runningState } = filterInfo;

		if (this.props.show) {
			let separator = '';

			machineTypeList = machineType.map((item) => {
				separator = item.last ? '' : ' ';

				return (
					<div>
						<Button
							size="sm"
							style={this.getButtonColor(item.active)}
							onClick={() =>
								this.onToggleEnumMachineType(item.index)
							}
							active={item.active}
						>
							{item.label}
						</Button>
						{separator}
					</div>
				);
			});

			runningStateList = runningState.map((item) => {
				separator = item.last ? '' : ' ';

				return (
					<div>
						<Button
							size="sm"
							style={this.getButtonColor(item.active)}
							onClick={() =>
								this.onToggleEnumRunningState(item.index)
							}
							active={item.active}
						>
							{item.label}
						</Button>
						{separator}
					</div>
				);
			});
		}

		return (
			<div>
				<Modal
					size="lg"
					style={{ maxWidth: '700px', width: '100%' }}
					isOpen={this.props.show}
					className="modal-content"
				>
					<ModalHeader>Hub Instances Filter</ModalHeader>

					<ModalBody>
						<h6>
							<b>Machine Type</b>
						</h6>
						<ButtonGroup>{machineTypeList}</ButtonGroup>
						<br />
						<br />

						<h6>
							<b>Running State</b>
						</h6>
						<ButtonGroup>{runningStateList}</ButtonGroup>
						<br />
						<br />

						<br />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.onValidate}>
							Validate
						</Button>{' '}
						<Button color="secondary" onClick={this.onCancel}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default HubInstanceFilter;
