import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import * as Const from '../Const';
import StatusError from './StatusError';
import { checkResponseJson, checkResponseBody } from './Utils.js';
import authHeader from '../services/auth-header';
import MerchantList from './MerchantList';
class VaultDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'Vault';

		const { vaultBasePath } = this.props.match.params;

		const getUrl = `${Const.API_BASE_PATH_VAULTS}/${vaultBasePath}`;
		console.log(
			'VaultDetail.componentDidMount: getUrl = ' + getUrl,
		);

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: authHeader(),
		});

		let drhrsp = checkResponseJson(response);
		console.log(
			'VaultDetail.componentDidMount: json = ' +
				JSON.stringify(response),
		);

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError) {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['vault']);

				if (drhRsp.statusError) {
					console.error(
						'MerchantDetail.componentDidMount: json = ' +
							JSON.stringify(jsonBody),
					);
				}
			});
		}

		this.setState({
			isLoading: false,
			body: drhRsp.body,
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
		});
	}

	render() {
		const { body, httpInfo, statusError, isLoading } = this.state;
		const { vaultBasePath } = this.props.match.params;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		const title = <h2>View Vault</h2>;

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						{title}
						<br />
						<StatusError
							id="statusError"
							name="statusError"
							statusError={statusError}
							httpInfo={httpInfo}
						/>
					</Container>
				</div>
			);
		}

		const item = body.vault;
		console.log('VaultDetail.render: name = ' + item.name);
		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br />
					<h4>
						<b>Vault</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Vault ID</Col>
						<Col className="hexIdGray">{item.vaultId}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Name</Col>
						<Col className="valMain fitwidth">{item.name}</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Vault scope</Col>
						<Col className="fitwidth">
							<b>{item.name.toUpperCase()}</b>
						</Col>
					</Row>
					<Row className="rowSpace">
						<Col xs="2">Base Path</Col>
						<Col>{'/' + item.basePath}</Col>
					</Row>

					<br />
					<br />
					<h4>
						<b>Resources</b>
					</h4>
					<Button color="link" size="lg">
						<Link
							to={`/api/rest/vaults/${vaultBasePath}/configuration`}
						>
							{'Configuration'}
						</Link>
					</Button>

					<br />
					<Button color="link" size="lg">
						{' '}
						{'Customers'}{' '}
					</Button>

					<br />
					<Button color="link" size="lg">
						<Link
							to={`/api/rest/vaults/${vaultBasePath}/receipts`}
						>
							{'Receipts'}
						</Link>
					</Button>

					<br />
					<Button color="link" size="lg">
						<Link
							to={`/api/rest/vaults/${vaultBasePath}/consentFlows`}
						>
							{'Consent Flows'}
						</Link>
					</Button>
					<br />
					<Button color="link" size="lg">
						<Link
							to={`/api/rest/vaults/${vaultBasePath}/depositReservations`}
						>
							{'Deposit Reservations'}
						</Link>
					</Button>

					<br />
					<br />
					<br />
					<h4>
						<b>Merchants</b>
					</h4>
					<MerchantList
						id="merchants"
						vaultBasePath={vaultBasePath}
						name="merchants"
					/>

					<Button color="secondary" tag={Link} to={`/api`}>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(VaultDetail);
