import React, { Component } from 'react';
import './App.css';
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';


import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import { Redirect } from 'react-router-dom';

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from './helpers/history';

import ApiHome from './api/ApiHome';
import MerchantTransactions from './api/MerchantTransactions';
import TransactionDetail from './api/TransactionDetail';
import CrmTokenDetail from './api/CrmTokenDetail';
import CrmTokenEdit from './api/CrmTokenEdit';
import MerchantList from './api/MerchantList';
import VaultList from './api/VaultList';
import VaultDetail from './api/VaultDetail';
import VaultConfiguration from './api/VaultConfiguration';
import MerchantDetail from './api/MerchantDetail';
import MerchantCrmTokens from './api/MerchantCrmTokens';
import VaultConsentFlows from './api/VaultConsentFlows';
import VaultReceipts from './api/VaultReceipts';
import ReceiptDetail from './api/ReceiptDetail';
import ConsentFlowDetail from './api/ConsentFlowDetail';
import ReceiptRepresentationDetail from './api/ReceiptRepresentationDetail';
import DepositReservation from './api/DepositReservation';
import HubInstances from './api/HubInstances';
import HubInstanceDetail from './api/HubInstanceDetail';


class App extends Component {

	constructor(props) {
		super(props);
		this.logOut = this.logOut.bind(this);

		this.state = {
		    currentUser: undefined
	    };

        history.listen((location) => {
            props.dispatch(clearMessage()); // clear message when changing location
        });
	}

	componentDidMount() {
		const user = this.props.user;

		if (user) {
			this.setState({
			    currentUser: user
			});
		}
	}

	logOut() {
		this.props.dispatch(logout());
	}

	render() {

		const { currentUser } = this.state;

		return (
			<Router history={history}>

				<div>
					<nav className="navbar navbar-expand navbar-dark bg-dark">
						<Link to={"/"} className="navbar-brand">
						Worldline | DRH
						</Link>

						<div className="navbar-nav mr-auto">

						</div>
{/* 
						{currentUser ? (
						<div className="navbar-nav ml-auto">
							<li className="nav-item">
							<Link to={"/profile"} className="nav-link">
								{currentUser.username}
							</Link>
							</li>
							<li className="nav-item">
							<a href="/login" className="nav-link" onClick={this.logOut}>
								LogOut
							</a>
							</li>
						</div>
						) : (
						<div className="navbar-nav ml-auto">
							<li className="nav-item">
							<Link to={"/login"} className="nav-link">
								Login
							</Link>
							</li>

						</div>
						)} */}
					</nav>

					<div className="container mt-3">
						<Switch>
							<Route exact path={["/", "/api"]} component={ApiHome} />
{/* 							<Route exact path="/login" component={Login} />
							<Route exact path="/register" component={Register} /> */}
							{/* <Route exact path="/profile" component={Profile} /> */}
							<Route path="/user" component={BoardUser} />
							<Route path="/moderator" component={BoardModerator} />
							<Route path="/admin" component={BoardAdmin} />
							<Route path='/api/rest/merchants' exact={true} component={MerchantList}/>
							<Route path='/api/rest/common/hubInstances' exact={true} component={HubInstances}/>
							<Route path='/api/rest/common/hubInstances/:hubInstanceId' exact={true} component={HubInstanceDetail}/>

							<Route path='/api/rest/merchants/:merchantBasePath' exact={true} component={MerchantDetail}/>
							<Route path='/api/rest/merchants/:merchantBasePath/transactions' exact={true} component={MerchantTransactions}/>
							<Route path='/api/rest/merchants/:merchantBasePath/crmTokens' exact={true} component={MerchantCrmTokens}/>
							<Route path='/api/rest/merchants/:merchantBasePath/transactions/:transactionId' exact={true} component={TransactionDetail}/>
							<Route path='/api/rest/merchants/:merchantBasePath/crmTokens/:crmTokenId' exact={true} component={CrmTokenDetail}/>
							<Route path='/api/rest/merchants/:merchantBasePath/crmTokens/:crmTokenId/form' exact={true} component={CrmTokenEdit}/>

							<Route path='/api/rest/vaults' exact={true} component={VaultList}/>
							<Route path='/api/rest/vaults/:vaultBasePath' exact={true} component={VaultDetail}/>
							<Route path='/api/rest/vaults/:vaultBasePath/configuration' exact={true} component={VaultConfiguration}/>
							<Route path='/api/rest/vaults/:vaultBasePath/consentFlows' exact={true} component={VaultConsentFlows}/>
							<Route path='/api/rest/vaults/:vaultBasePath/consentFlows/:consentFlowId' exact={true} component={ConsentFlowDetail}/>
							<Route path='/api/rest/vaults/:vaultBasePath/receipts' exact={true} component={VaultReceipts}/>
							<Route path='/api/rest/vaults/:vaultBasePath/receipts/:receiptId' exact={true} component={ReceiptDetail}/>
							<Route path='/api/rest/vaults/:vaultBasePath/receipts/:receiptId/representations/:index' exact={true} component={ReceiptRepresentationDetail}/>
							<Route path='/api/rest/vaults/:vaultBasePath/depositReservations' exact={true} component={DepositReservation}/>


						    <Redirect to="/" />


						</Switch>
					</div>
				</div>
			</Router>
		)
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(App);
