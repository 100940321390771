import React, { Component } from 'react';
import StatusError from './StatusError';
import { checkResponseJson, checkResponseBody} from './Utils.js';
import {
	Container,
	Row,
	Col
} from 'reactstrap';

const Const = require("../Const");

class DRHInfo extends Component {

	constructor(props) {
		super(props);
		this.state = {items: [], isLoading: true};
	}

	async componentDidMount() {
		this.setState({isLoading: true});

		const getUrl = `${Const.DRH_ENDPOINT_BASE}/api/info/version`;
		console.log("DRHInfo.componentDidMount: getUrl = " + getUrl);

		const response = await fetch(
			getUrl,
			{
				method: 'GET'
			});

		let drhRsp = checkResponseJson(response);

		if (!drhRsp.statusError)  {
			await response.json().then((jsonBody) => {
				checkResponseBody(jsonBody, drhRsp, ['drh']);
				console.log("DRHInfo.componentDidMount: json = " + JSON.stringify(jsonBody));

				if (drhRsp.statusError) {
					console.error("DRHInfo.componentDidMount: json = " + JSON.stringify(jsonBody));
				}
			});
		}

		this.setState({ 
			isLoading: false,
			item:  drhRsp.body ? drhRsp.body.drh : {},
			httpInfo: drhRsp.httpInfo,
			statusError: drhRsp.statusError,
		});	
	}

	render() {
		const {item, httpInfo, statusError, isLoading} = this.state;

		if (isLoading) {
			return <p>Loading...</p>;
		}

		if (statusError) {
			return (
				<div>
					<Container fluid style={{ paddingLeft: 40 }}>
						<br/>
						<StatusError id="statusError" name="statusError" statusError={statusError} httpInfo={httpInfo} />
					</Container>
				</div>
			);
		}

		return (
			<div>
				<Row className="rowSpace">
					<Col xs="2">Version</Col>
					<Col className="hexId">{`${item.version}`}</Col>
				</Row>
			</div>
		);

	}
}

export default DRHInfo;