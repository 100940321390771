import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';
import HubInstanceList from './HubInstanceList';

class HubInstances extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.title = 'HubInstances';
	}

	render() {
		const title = <h2>Hub Instances </h2>;

		let browserSearchParams = null;

		if (this.props.location) {
			browserSearchParams = new URLSearchParams(
				this.props.location.search,
			);
			console.log(
				'HubInstances.render: browserSearchParams = ' +
					browserSearchParams.toString(),
			);
		}

		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					{title}
					<br />

					<h4>
						<b>Instances </b>
					</h4>

					<HubInstanceList
						id="hubinstances"
						name="hubinstances"
					/>

					<br />
					<Button color="secondary" tag={Link} to={`/api/rest`}>
						Back
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(HubInstances);
