import React, { Component } from 'react';

import {
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
} from 'reactstrap';

import {
	stringToDateDDMMYYYY,
	dateToStringDDMMYYYY,
	isDateValid,
} from './Utils.js';

class CrmTokenFilter extends Component {
	constructor(props) {
		super(props);

		const { filterInfo } = this.props;

		this.state = {
			isLoading: true,
			filterInfo: this.props.filterInfo,
		};

		this.state = {
			filterInfo: filterInfo,
			inputState: {
				// - (empty), NOK, OK
				dateFrom: filterInfo.dateFrom ? 'OK' : '-',
				dateTo: filterInfo.dateTo ? 'OK' : '-',
				lastDays: filterInfo.lastDays ? 'OK' : '-',
				tnxCountMax: filterInfo.tnxCountMax ? 'OK' : '-',
				tnxCountMin: filterInfo.tnxCountMin ? 'OK' : '-',
				tokenValue: filterInfo.tokenValue ? 'OK' : '-',


			},
		};

		console.log(
			'CrmTokenFilter.constructor: show = ' + this.props.show,
		);
	}

	async componentDidMount() {
		console.log('CrmTokenFilter.componentDidMount');
	}

	onToggleEnumFirstTokenizedBy = (selected) => {
		console.log(
			'CrmTokenFilter.onToggleEnumFirstTokenizedBy: selected = ' +
				selected,
		);

		const { filterInfo } = this.state;
		const { firstBy } = filterInfo;

		firstBy[selected]['active'] = !firstBy[
			selected
		]['active'];

		this.setState({
			filterInfo: filterInfo,
		});
	};

	onToggleEnumAssignmentToCustomerList = (selected) => {
		console.log(
			'CrmTokenFilter.onToggleEnumAssignmentToCustomer: selected = ' +
				selected,
		);

		const { filterInfo } = this.state;
		const { assignment } = filterInfo;

		assignment[selected][
			'active'
		] = !assignment[selected]['active'];

		this.setState({
			filterInfo: filterInfo,
		});
	};

	getButtonColor = (selected) => {
		if (selected)
			return {
				backgroundColor: '#f1c1f1',
				color: 'black',
				marginLeft: '6px',
				paddingRight: '6px',
			};

		return {
			backgroundColor: '#f1f1f1',
			color: 'black',
			marginLeft: '6px',
			paddingRight: '6px',
		};
	};

	onValidate = () => {
		console.log('CrmTokenFilter.onValidate');
		this.props.onValidate(this.state.filterInfo);
	};

	onCancel = () => {
		console.log('CrmTokenFilter.onCancel');
		this.props.onCancel();
	};

	getColorClass = (inputState) => {
		if (inputState) {
			if (inputState === 'OK') return 'inputValOk';
			if (inputState === 'NOK') return 'inputValErr';
		}

		return 'inputValEmpty';
	};
	onChangemaxCount = (event) => {
		const target = event.target;
		const valInput = target.value;

		const { filterInfo, inputState } = this.state;

		let stateNew = '-';

		let valNew = 0;

		if (valInput) {
			valNew = parseInt(valInput);

			if (isNaN(valNew) || valNew <= 0) {
				valNew = 0;
				stateNew = 'NOK';
			} else stateNew = 'OK';
		}

		let stateCurr = inputState.tnxCountMax;

		if (stateCurr === stateNew && stateCurr !== 'OK') return;

		filterInfo.tnxCountMax = valNew;
		inputState.tnxCountMax = stateNew;

		this.setState({
			filterInfo: filterInfo,
			inputState: inputState,
		});
	};
	onchangeTokenValue= (event) => {
		const target = event.target;
		const valInput = target.value;

		const { filterInfo, inputState } = this.state;

		let stateNew = 'OK';

	 

		let stateCurr = inputState.tokenValue;

		if (stateCurr === stateNew && stateCurr !== 'OK') return;

		filterInfo.tokenValue = valInput;
		inputState.tokenValue = stateNew;

		this.setState({
			filterInfo: filterInfo,
			inputState: inputState,
		});
	};
	onChangeminCount = (event) => {
		const target = event.target;
		const valInput = target.value;

		const { filterInfo, inputState } = this.state;

		let stateNew = '-';

		let valNew = 0;

		if (valInput) {
			valNew = parseInt(valInput);

			if (isNaN(valNew) || valNew <= 0) {
				valNew = 0;
				stateNew = 'NOK';
			} else stateNew = 'OK';
		}

		let stateCurr = inputState.tnxCountMin;

		if (stateCurr === stateNew && stateCurr !== 'OK') return;

		filterInfo.tnxCountMin = valNew;
		inputState.tnxCountMin = stateNew;

		this.setState({
			filterInfo: filterInfo,
			inputState: inputState,
		});
	};

	onChangeLastDays = (event) => {
		const target = event.target;
		const valInput = target.value;

		const { filterInfo, inputState } = this.state;

		let stateNew = '-';

		let valNew = 0;

		if (valInput) {
			valNew = parseInt(valInput);

			if (isNaN(valNew) || valNew <= 0) {
				valNew = 0;
				stateNew = 'NOK';
			} else stateNew = 'OK';
		}

		let stateCurr = inputState.lastDays;

		if (stateCurr === stateNew && stateCurr !== 'OK') return;

		filterInfo.lastDays = valNew;
		inputState.lastDays = stateNew;

		this.setState({
			filterInfo: filterInfo,
			inputState: inputState,
		});
	};

	onChangeDateFrom = (event) => {
		const { value } = event.target;

		this.onChangeDateFromTo('dateFrom', value);
	};

	onChangeDateTo = (event) => {
		const { value } = event.target;

		this.onChangeDateFromTo('dateTo', value);
	};

	onChangeDateFromTo = (field, valEntered) => {
		const { filterInfo, inputState } = this.state;

		let stateCurr = inputState[field];

		let valNew = null;

		let stateNew = '-';

		if (valEntered) {
			stateNew = 'NOK';
			const dateEntered = stringToDateDDMMYYYY(valEntered);
			const timeEntered = dateEntered
				? dateEntered.getTime()
				: NaN; // dateEntered.getTime() returns NaN if invalid

			if (isNaN(timeEntered)) {
				console.warn(
					'CrmTokenFilter.onChangeDateFromTo: not a date = ' +
						valEntered,
				);
			} else {
				const timeNow = Date.now();
				if (timeEntered > timeNow) {
					console.warn(
						'CrmTokenFilter.onChangeDateFromTo: date greater than now= ' +
							valEntered,
					);
				} else {
					valNew = dateToStringDDMMYYYY(dateEntered);
					stateNew = 'OK';
				}
			}
		}

		if (stateCurr === stateNew && stateCurr !== 'OK') return;

		console.log(
			'CrmTokenFilter.onChangeDateFromTo: dateNew = ' + valNew,
		);

		filterInfo[field] = valNew;
		inputState[field] = stateNew;

		this.setState({
			filterInfo: filterInfo,
		});
	};

	render() {
		console.log(`CrmTokenFilter.render: show = ${this.props.show}`);

		const { filterInfo, inputState } = this.state;

		let firstTokenizedByList = '';
		let assignmentToCustomerList = '';

		const { firstBy, assignment } = filterInfo;

		if (this.props.show) {
			let separator = '';

			firstTokenizedByList = firstBy.map((item) => {
				separator = item.last ? '' : ' ';

				return (
					<div>
						<Button
							size="sm"
							style={this.getButtonColor(item.active)}
							onClick={() =>
								this.onToggleEnumFirstTokenizedBy(item.index)
							}
							active={item.active}
						>
							{item.label}
						</Button>
						{separator}
					</div>
				);
			});

			assignmentToCustomerList = assignment.map(
				(item) => {
					separator = item.last ? '' : ' ';

					return (
						<div>
							<Button
								size="sm"
								style={this.getButtonColor(item.active)}
								onClick={() =>
									this.onToggleEnumAssignmentToCustomerList(
										item.index,
									)
								}
								active={item.active}
							>
								{item.label}
							</Button>
							{separator}
						</div>
					);
				},
			);
		}
		const defaultDateFrom = filterInfo.dateFrom
			? filterInfo.dateFrom.replace(/-/g, '/')
			: '';
		const defaultDateTo = filterInfo.dateTo
			? filterInfo.dateTo.replace(/-/g, '/')
			: '';

		const daysNotEmpty = inputState.lastDays !== '-';
		const dateNotEmpty =
			inputState.dateFrom !== '-' || inputState.dateTo !== '-';

		const validateDisabled =
			inputState.lastDays === 'NOK' ||
			inputState.dateFrom === 'NOK' ||
			inputState.dateTo === 'NOK';

		return (
			<div>
				<Modal size="lg" style={{maxWidth: '700px', width: '100%'}} isOpen={this.props.show}>
					<ModalHeader>CRM Tokens Filter </ModalHeader>

					<ModalBody>
					<div style={{ marginBottom: '7px' }}>
							<span className="inputLabel">Token value   </span>
							 
						</div>
						<Input
							type="text"
							disabled={dateNotEmpty}
 							name="tokenValue"
							id="tokenValue"
							style={{}}
							defaultValue={filterInfo.tokenValue || ''}
							onChange={this.onchangeTokenValue}
						/>
						<br />
						<br />
						<h6>
							<b>First tokenized by </b>
						</h6>
						<ButtonGroup>{firstTokenizedByList}</ButtonGroup>
						<br />
						<br />

						<h6>
							<b>Assignment to customer</b>
						</h6>
						<ButtonGroup>
							{assignmentToCustomerList}
						</ButtonGroup>
						<br />
						<br />
						 
						<br />
						<div style={{ marginBottom: '7px' }}>
							<span className="inputLabel">Min transaction count </span>
							&nbsp;&nbsp; (
							<span
								className={this.getColorClass(
									inputState.tnxCountMin,
								)}
							>
								N
							</span>
							)
						</div>
						<Input
							type="text"
							pattern="[0-9]*"
							name="tnxCountMin"
							id="tnxCountMin"
							style={{}}
							defaultValue={filterInfo.tnxCountMin || ''}
							onChange={this.onChangeminCount}
						/>
						<br />
						<br />
						<div style={{ marginBottom: '7px' }}>
							<span className="inputLabel">Max transaction count</span>
							&nbsp;&nbsp; (
							<span
								className={this.getColorClass(
									inputState.tnxCountMax,
								)}
							>
							N
							</span>
							)
						</div>
						<Input
							type="text"
							pattern="[0-9]*"
							name="tnxCountMax"
							id="tnxCountMax"
							style={{}}
							defaultValue={filterInfo.tnxCountMax || ''}
							onChange={this.onChangemaxCount}
						/>
						<br />
						<div style={{ marginBottom: '7px' }}>
							<span className="inputLabel">Last Days</span>
							&nbsp;&nbsp; (
							<span
								className={this.getColorClass(
									inputState.lastDays,
								)}
							>
								N
							</span>
							)
						</div>
						<Input
							type="text"
							disabled={dateNotEmpty}
							pattern="[0-9]*"
							size="3"
							name="lastDays"
							id="lastDays"
							style={{}}
							defaultValue={filterInfo.lastDays || ''}
							onChange={this.onChangeLastDays}
						/>
						<br />

						<div style={{ marginBottom: '7px' }}>
							<span className="inputLabel">From Date</span>
							&nbsp;&nbsp; (
							<span
								className={this.getColorClass(
									inputState.dateFrom,
								)}
							>
								DD/MM/YYYY
							</span>
							)
						</div>
						<Input
							type="text"
							disabled={daysNotEmpty}
							name="dateFrom"
							id="dateFrom"
							defaultValue={defaultDateFrom}
							onChange={this.onChangeDateFrom}
						/>
						<br />

						<div style={{ marginBottom: '7px' }}>
							<span className="inputLabel">To Date</span>
							&nbsp;&nbsp; (
							<span
								className={this.getColorClass(
									inputState.dateTo,
								)}
							>
								DD/MM/YYYY
							</span>
							)
						</div>
						<Input
							type="text"
							disabled={daysNotEmpty}
							name="dateTo"
							id="dateTo"
							defaultValue={defaultDateTo}
							onChange={this.onChangeDateTo}
						/>
						<br />
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							disabled={validateDisabled}
							onClick={this.onValidate}
						>
							Validate
						</Button>{' '}
						<Button color="secondary" onClick={this.onCancel}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default CrmTokenFilter;
