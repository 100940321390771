import React, { Component } from 'react';
import '../App.css';
import * as Const from '../Const';
 import { Button, Container, Row, Col } from 'reactstrap';
 import { Link, withRouter } from 'react-router-dom';

import DRHInfo from './DRHInfo';
import VaultList from './VaultList';

class ApiHome extends Component {
	render() {
		return (
			<div>
				<Container fluid style={{ paddingLeft: 50 }}>
					<br />
					<br />
					<h4>
						<b>DRH Client</b>
					</h4>
					<Row className="rowSpace">
						<Col xs="2">Version</Col>
						<Col className="hexId">{`${Const.CLIENT_VERSION}`}</Col>
					</Row>
					<br />
					<br />
					<h4>
						<b>DRH Server</b>
					</h4>
					<DRHInfo id="drhinfo" name="drhinfo" />
					<br />
					<br />
					<h4>
						<b>DRH Resources</b>
					</h4>
					<Button color="link" size="lg">
						<Link
							to={`/api/rest/common/hubInstances`}
						>
							Hub Instances
						</Link>
					</Button>					<br />
					<br />
					<br />
					<br />

					<h4>
						<b>Vaults </b>
					</h4>
					<VaultList id="vaults" name="vaults" />

					<br />
				</Container>
			</div>
		);
	}
}

export default ApiHome;
